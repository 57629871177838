import api from './index';

export default {
  getPageData() {
    return api.get(`/dentist_pages`);
  },
  updatePageData(payload) {
    return api.put(`/dentist_pages`, { request: payload });
  },
};
