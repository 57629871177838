import api from './index';

export default {
  updatePageContent(payload) {
    return api.put(`/kol_pages`, { request: payload });
  },
  getPageContent() {
    return api.get(`/kol_pages`);
  },
  listKols(q, page, size) {
    let url = '/kols';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page && filters.push(`page=${page}`);
    size && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  detailKols(id) {
    return api.get(`/kols/${id}`);
  },
  createKols(payload) {
    return api.post(`/kols`, { request: payload });
  },
  updateKols(id, payload) {
    return api.put(`/kols/${id}`, { request: payload });
  },
  updateKolsOrder(payload) {
    return api.put(`/kols`, { request: payload });
  },
  deleteKols(id) {
    return api.delete(`/kols/${id}`);
  },
};
