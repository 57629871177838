import api from '../v4';

export default {
  createTestimony(payload) {
    return api.post(`/before_after_testimonies`, { request: payload });
  },
  listTestimonies(page, size) {
    return api.get(
      `/before_after_testimonies?pagination=true&page=${page}&size=${size}`
    );
  },
  getTestimony(id) {
    return api.get(`/before_after_testimonies/${id}`);
  },
  updateTestimonyDetail(id, payload) {
    return api.put(`/before_after_testimonies/${id}`, { request: payload });
  },
  updateTestimonyOrder(payload) {
    return api.put(`/before_after_testimonies`, { request: payload });
  },
  deleteTestimonyDetail(id) {
    return api.delete(`/before_after_testimonies/${id}`);
  },
};
