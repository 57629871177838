import api from './index';

export default {
  get(q, category, start_date, end_date, status, page = 1, sort_by, sort_dir) {
    let url = '/articles';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    start_date && filters.push(`start_date=${start_date}`);
    end_date && filters.push(`end_date=${end_date}`);
    status && filters.push(`status=${status}`);
    category && filters.push(`article_category=${category}`);
    page && filters.push(`page=${page}`);
    sort_by && filters.push(`sort_by=${sort_by}`);
    sort_dir && filters.push(`sort_dir=${sort_dir}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getById(id) {
    return api.get(`articles/${id}`);
  },
  post(payload) {
    return api.post(`articles`, { request: payload });
  },
  update(payload, id) {
    return api.put(`articles/${id}`, { request: payload });
  },
  bulkUpdate(payload) {
    return api.put(`articles/bulk`, { request: payload });
  },
  updateStatus(params, id) {
    return api.patch(`articles/${id}?${params}`);
  },
  updateMultiple(payload) {
    return api.put(`/articles`, { articles: payload });
  },
  delete(id) {
    return api.delete(`articles/${id}`);
  },
};
