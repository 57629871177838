import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Layout, Typography, message } from 'antd';

import './style.scss';
import tracking from 'api/tracking';

const { Header, Content } = Layout;
const { Title } = Typography;

function TrackingEvent({ form }) {
  const [trackingData, setTrackingData] = useState({});
  const { getFieldDecorator } = form;

  const getAllTrackingData = async () => {
    const res = await tracking.getAllTracking();
    setTrackingData(res.data.data);
  };

  const handleSubmitTrackingEvents = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      await tracking.updateTracking({
        request: values,
      });
      getAllTrackingData();
      message.success('Successfully update tracking events!');
    });
  };

  useEffect(() => {
    getAllTrackingData();
    return () => setTrackingData({});
  }, []);

  return (
    <Layout className="pd-cms-video-section pd-cms-form-1">
      <Header>
        <Title>Tracking Event</Title>
      </Header>
      <Content>
        <Form layout="horizontal" onSubmit={handleSubmitTrackingEvents}>
          <Form.Item
            label="Meta"
            labelCol={{ span: 1 }}
            wrapperCol={{ span: 6 }}>
            {getFieldDecorator('meta_test_code', {
              initialValue: trackingData.meta_test_code || '',
            })(<Input placeholder="Meta Test Code" />)}
          </Form.Item>
          <Form.Item
            label="Tiktok"
            labelCol={{ span: 1 }}
            wrapperCol={{ span: 6 }}>
            {getFieldDecorator('tik_tok_test_code', {
              initialValue: trackingData.tik_tok_test_code || '',
            })(
              <Input
                placeholder="Tiktok Test Code"
                style={{ marginLeft: '5px' }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const TrackingEvents = Form.create({
  name: 'tracking_event_form',
})(TrackingEvent);
