import api from './index';

export default {
  get(qs) {
    return api.get(`/comments/articles?${qs}`);
  },

  update(id, request) {
    return api.patch(`/comments/articles/${id}`, { request });
  },

  getCounters(qs) {
    return api.get(`/comments/counters?${qs}`);
  },

  bulkUpdate(request) {
    return api.patch(`/comments/articles`, { request });
  },
};
