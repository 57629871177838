import api from './index';

import axios from 'axios';

export default {
  get() {
    return api.get(`article-categories`);
  },
  getPublicCategories() {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v3/public/pages/articles`
    );
  },
};
