import React, { useContext } from 'react';
import { Menu, Layout, Typography } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

import { CommentContext } from 'contexts/comment';
import { menus } from './menu';

import './style.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

export const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  const { counters } = useContext(CommentContext || {});
  const currentPath = _.get(location, 'pathname');

  function handleLogout() {
    localStorage.removeItem('cms_token');
    localStorage.removeItem('current_admin');
    localStorage.removeItem('current_admin_id');
    history.push('/');
  }

  function renderMenu(opt) {
    const {
      name = '',
      menu_id = '',
      link = '',
      type = 'Menu',
      children = [],
    } = opt;
    if (type === 'Menu') {
      return (
        <Menu.Item key={link} id={menu_id}>
          <Link to={link}>
            {name}

            {name === 'Waiting Approval' && (
              <span className="badge">{counters}</span>
            )}
          </Link>
        </Menu.Item>
      );
    } else {
      return (
        <SubMenu id={menu_id} key={name} title={<span>{name}</span>}>
          {children.map((opt) => renderMenu(opt))}
        </SubMenu>
      );
    }
  }

  // 36

  return (
    <Sider width="300" className="pd-cms-sidebar">
      <div className="wrapper">
        <Title level={3} style={{ marginBottom: 0 }} id="logo-rata-cms">
          <Link to={'/'}>
            <div className="logo-container">
              <img
                src={`${process.env.PUBLIC_URL}/img/logo/logo-red.png`}
                alt="Rata"
              />
            </div>
          </Link>
        </Title>
        <div className="menu">
          <Menu
            id="menu-page"
            mode="inline"
            defaultSelectedKeys={currentPath}
            defaultOpenKeys={[
              _.get(
                menus.find(
                  (menu) =>
                    menu.type === 'SubMenu' &&
                    _.get(menu, 'children', []).find(
                      (child) => child.link === currentPath
                    )
                ),
                'name'
              ),
            ]}>
            {menus.map((opt) => renderMenu(opt))}
          </Menu>
        </div>
        <Title level={4} onClick={handleLogout} id="btn-logout">
          Logout
        </Title>
      </div>
    </Sider>
  );
};
