import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import KolsApi from 'api/kols';

import { PageSpinner } from 'components/page-spinner';

import '../style.scss';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  NAME: 'name',
  USERNAME: 'username',
  MEDIA_URL: 'media_url',
  MEDIA_URL_ALT: 'media_url_alt',
};

export function KolsCreateEdit({ form, match, history }) {
  const [kols, setKols] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    async function getKolsDetail() {
      setLoadingPage(true);
      try {
        const { data } = await KolsApi.detailKols(id);
        const kolsData = data.data;

        setKols(kolsData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getKolsDetail();
    }
  }, [id]);

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!kols.media_url) {
        message.error('Kols Picture must be filled');
        return;
      }

      if (!err) {
        try {
          setLoadingButton(true);

          const payload = {
            ...kols,
            ...values,
          };

          if (id) {
            await KolsApi.updateKols(id, payload);
            message.success('Data has been updated');
          } else {
            await KolsApi.createKols(payload);
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/kols/manage',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/kols/manage">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} KOL
        </Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Name">
            {getFieldDecorator(FIELDS.NAME, {
              rules: [
                { required: true, message: 'Please input name!' },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Name should not start or end characters with spaces',
                },
              ],
              initialValue: kols[FIELDS.NAME],
            })(<Input placeholder="Write your name here" />)}
          </Form.Item>

          <Form.Item label="Username">
            {getFieldDecorator(FIELDS.USERNAME, {
              rules: [
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Username should not start or end characters with spaces',
                },
              ],
              initialValue: kols[FIELDS.USERNAME],
            })(<Input placeholder="Write your username here" />)}
          </Form.Item>

          <Form.Item label="Image - 2:3 (250x270)" required>
            <PdUploader
              purpose="kols"
              imgUrl={_.get(kols, FIELDS.MEDIA_URL, '')}
              afterUpload={(url) => {
                setKols((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Image Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_URL_ALT, {
              rules: [
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Image ALT should not start or end characters with spaces',
                },
              ],
              initialValue: kols[FIELDS.MEDIA_URL_ALT],
            })(<Input placeholder="Please enter image alt text" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const KolsCreateEditForm = Form.create({
  name: 'validate_kolss_edit',
})(KolsCreateEdit);

export default { KolsCreateEdit };
