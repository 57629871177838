import React from 'react';
import DOMPurify from 'dompurify';

export default function HtmlRender({ content, className = '' }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      className={className}></div>
  );
}
