import api from './index';

export default {
  get(q, status, page, start_date, end_date, sort_by, sort_dir) {
    let url = '/promotions';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    status && filters.push(`active=${status}`);
    page && filters.push(`page=${page}`);
    start_date && filters.push(`start_date=${start_date}`);
    end_date && filters.push(`end_date=${end_date}`);
    sort_by && filters.push(`sort_by=${sort_by}`);
    sort_dir && filters.push(`sort_dir=${sort_dir}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  show(id) {
    return api.get(`/promotions/${id}`);
  },
  create(payload) {
    return api.post(`/promotions`, { request: payload });
  },
  update(id, payload) {
    return api.put(`/promotions/${id}`, { request: payload });
  },
  updateStatusPromotion(params) {
    return api.patch(`/promotions/${params.id}?set_active=${params.is_active}`);
  },
  delete(id) {
    return api.delete(`/promotions/${id}`);
  },
  duplicate(payload) {
    return api.post(`/promotions/duplicate`, { request: payload });
  },
};
