import React, { useEffect, useState, useRef, useCallback } from 'react';
import LoadingContent from 'components/loading-skeleton';
import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  // Form,
  // Input,
} from 'antd';

import BenefitsApi from 'api/benefit';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';
import HtmlRender from 'components/html-render';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export const Benefits = () => {
  const [benefits, setBenefits] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [page, setPage] = useState(1);
  let observer = useRef();
  const [more, setMore] = useState(false);

  async function deletingBenefit(id) {
    try {
      await BenefitsApi.deleteBenefit(id);
      message.success('Item has been deleted');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletingBenefit(id);
      },
      onCancel() {},
    });
  }

  async function getBenefits() {
    setLoadingPage(true);
    try {
      const { data } = await BenefitsApi.listBenefits('', page, 10);
      const benefitList = data.data;

      setMore(Boolean(data.next_page));
      setBenefits((prev) => [...new Set([...prev, ...benefitList])]);
    } catch (error) {
      message.error(error, 'error');
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getBenefits();
  }, [page]);

  const lastElementRef = useCallback(
    (element) => {
      if (observer.current) observer.current.disconnect();
      if (!more) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && more) {
          setLoadingPage(true);
          setTimeout(() => {
            setPage((prev) => prev + 1);
            setLoadingPage(false);
          }, 800);
        }
      });
      if (element) observer.current.observe(element);
    },
    [more]
  );

  const SortableItem = (benefit) => {
    return (
      <div ref={lastElementRef}>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          className="benefit-item draggable-item"
          gutter={[32, 32]}>
          {/* image */}
          <Col xs={4}>
            <div style={{ width: 100, height: '100%' }}>
              <img
                src={benefit.media_url}
                alt={benefit.media_url_alt}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </div>
          </Col>

          {/* message and name */}
          <Col xs={12}>
            <div className="container--center">
              <HtmlRender content={benefit.title} className="title" />
            </div>
          </Col>

          {/* button group */}
          <Col xs={8}>
            <Row type="flex" justify="end">
              <Button
                type="danger"
                onClick={handleDelete.bind(this, benefit.id)}>
                Delete
              </Button>
              <Button style={{ marginLeft: 24 }} type="primary">
                <Link to={`/cms/benefits/edit/${benefit.id}`}>Edit</Link>
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...benefits];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);
    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });
    try {
      setBenefits(list);
      await BenefitsApi.updateBenefitOrder(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  return (
    <Layout className="pd-cms-home-testimonials pd-cms-form-1">
      <Header>
        <Title>Benefits</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/benefits/create'}>Add New Benefit</Link>
        </Button>

        <PdSortable
          render={SortableItem}
          items={benefits}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
        {loadingPage && <LoadingContent />}
      </Content>
    </Layout>
  );
};
