import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import moment from 'moment';

import FormServices from 'api/forms';

import { useDebounce } from 'hooks/useDebounce';

import {
  Col,
  Icon,
  Layout,
  Pagination,
  Table,
  Typography,
  Row,
  Input,
} from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const SIZE = 10;

const Results = () => {
  const history = useHistory();
  const { formId } = useParams();

  const [results, setResults] = useState([]);
  const [formObject, setFormObject] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);

  const debounced = useDebounce(keyword);

  const queries = {
    formId,
    q: debounced,
    page,
    size: SIZE,
  };

  const getAllResultUser = async () => {
    try {
      const response = await FormServices.getResultUser(queries);

      setResults(response.data);
    } finally {
      setLoadingPage(false);
    }
  };

  const getSingleForm = async () => {
    try {
      const response = await FormServices.getFormById(formId);

      const { data } = response.data;

      setFormObject(data);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleKeyword = (e) => setKeyword(e.target.value);

  const handlePagination = (e) => {
    setPage(e);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Link
          to={`/cms/forms/${formId}/answers/${record.result_id}`}
          style={{ color: '#1890ff' }}>
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, record) => <>{record.phone || '-'}</>,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (_, record) => <>{record.location || '-'}</>,
    },
    {
      title: 'Submitted Date',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
      render: (_, record) => {
        const publish_date = moment(record.submitted_at).format('DD MMMM YYYY');
        return publish_date;
      },
    },
  ];

  useEffect(() => {
    getAllResultUser();

    return () => setResults([]);
  }, [page, debounced]);

  useEffect(() => {
    getSingleForm();

    return () => setFormObject({});
  }, []);

  return (
    <Layout className="rata-cms-forms-results pd-cms-form-1">
      <Header>
        <Title>
          <Icon type="caret-left" onClick={() => history.goBack()} />
          Results {formObject ? `- ${formObject.purpose}` : null}{' '}
        </Title>
      </Header>

      <Content>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Col lg={12} xs={24}>
            <Input placeholder="Search Result" onChange={handleKeyword} />
          </Col>
        </div>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={results.data}
              columns={columns}
              pagination={false}
              scroll={{ x: 1000 }}
              rowKey="id"
              loading={loadingPage}
            />

            {results.data && results.data.length > 0 ? (
              <div className="pagination">
                <Pagination
                  current={results.current_page}
                  onChange={handlePagination}
                  pageSize={SIZE}
                  total={results.total_data}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Results;
