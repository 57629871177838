import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Layout,
  Radio,
  Typography,
  message,
} from 'antd';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import ColorPicker from 'rc-color-picker';
import _ from 'lodash';
import ComparisonsApi from 'api/comparisons';

import { PageSpinner } from 'components/page-spinner';
import { PdUploader } from 'components/pd-uploader';

import '../style.scss';
import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],
    [
      {
        color: [
          '#000000',
          '#ffffff',
          '#eeeeee',
          '#cccccc',
          '#a1aeb7',
          '#9a9a9a',
          '#686666',
          '#111111',
          '#00ac42',
          '#087b6c',
          '#75235e',
          '#ff9999',
          '#c00d1e',
          '#fef8f9',
          '#1b7ae2',
          '#be0d1e',
          '#fcf3f4',
          '#e0dcd9',
        ],
      },
    ],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'link',
  'color',
];

export function ComparisonsCreateEdit({ form, match, history }) {
  const [comparison, setComparison] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    async function getComparison() {
      setLoadingPage(true);
      try {
        const { data } = await ComparisonsApi.detailComparisons(id);
        const comparisonData = data.data;

        setComparison(comparisonData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getComparison();
    }
  }, [id]);

  const { getFieldDecorator, getFieldValue } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          const payload = {
            ...comparison,
            ...values,
          };

          if (id) {
            await ComparisonsApi.updateComparisonsDetail(id, payload);
            message.success('Data has been updated');
          } else {
            await ComparisonsApi.createComparisons(payload);
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/comparisons/manage',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/comparisons/manage">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Comparison
        </Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Aspect">
            {getFieldDecorator('aspect', {
              rules: [
                { required: true, message: 'Please input aspect!' },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Aspect should not start or end characters with spaces',
                },
              ],
              initialValue: comparison?.aspect || '',
            })(<Input placeholder="Please enter aspect" />)}
          </Form.Item>

          <Form.Item label="Type Rata">
            {getFieldDecorator('aligner_rata.type', {
              rules: [{ required: true, message: 'Please choose type!' }],
              initialValue: comparison?.aligner_rata?.type || '',
            })(
              <Radio.Group
                onChange={(e) => {
                  const typeValue = e.target.value;
                  form.setFieldsValue({
                    'aligner_rata.type': typeValue,
                    'aligner_rata.value': '',
                  }); // Reset aligner_rata.value on type change
                }}>
                <Radio value={'TEXT'}>TEXT</Radio>
                <Radio value={'CHOICE'}>CHOICE</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {getFieldValue('aligner_rata.type') === 'TEXT' && (
            <Form.Item label="Rata">
              {getFieldDecorator('aligner_rata.value', {
                rules: [{ required: true, message: 'Please input value!' }],
                initialValue: comparison?.aligner_rata?.value || '',
              })(
                <ReactQuill
                  placeholder="Write here"
                  modules={modules}
                  formats={formats}
                />
              )}
            </Form.Item>
          )}
          {getFieldValue('aligner_rata.type') === 'CHOICE' && (
            <Form.Item label="Rata">
              {getFieldDecorator('aligner_rata.value', {
                rules: [{ required: true, message: 'Please choose value!' }],
                initialValue: comparison?.aligner_rata?.value || '',
              })(
                <Radio.Group>
                  <Radio value={'CHECK'}>CHECK</Radio>
                  <Radio value={'CROSS'}>CROSS</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          )}

          <Form.Item label="Type Braces">
            {getFieldDecorator('braces.type', {
              rules: [{ required: true, message: 'Please choose type!' }],
              initialValue: comparison?.braces?.type || '',
            })(
              <Radio.Group
                onChange={(e) => {
                  const typeValue = e.target.value;
                  form.setFieldsValue({
                    'braces.type': typeValue,
                    'braces.value': '',
                  }); // Reset braces.value on type change
                }}>
                <Radio value={'TEXT'}>TEXT</Radio>
                <Radio value={'CHOICE'}>CHOICE</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {getFieldValue('braces.type') === 'TEXT' && (
            <Form.Item label="Type Braces">
              {getFieldDecorator('braces.value', {
                rules: [{ required: true, message: 'Please input value!' }],
                initialValue: comparison?.braces?.value || '',
              })(
                <ReactQuill
                  placeholder="Write here"
                  modules={modules}
                  formats={formats}
                />
              )}
            </Form.Item>
          )}
          {getFieldValue('braces.type') === 'CHOICE' && (
            <Form.Item label="Braces">
              {getFieldDecorator('braces.value', {
                rules: [{ required: true, message: 'Please choose value!' }],
                initialValue: comparison?.braces?.value || '',
              })(
                <Radio.Group>
                  <Radio value={'CHECK'}>CHECK</Radio>
                  <Radio value={'CROSS'}>CROSS</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          )}

          <Form.Item label="Type Other Brands">
            {getFieldDecorator('aligner_other.type', {
              rules: [{ required: true, message: 'Please choose type!' }],
              initialValue: comparison?.aligner_other?.type || '',
            })(
              <Radio.Group
                onChange={(e) => {
                  const typeValue = e.target.value;
                  form.setFieldsValue({
                    'aligner_other.type': typeValue,
                    'aligner_other.value': '',
                  }); // Reset aligner_other.value on type change
                }}>
                <Radio value={'TEXT'}>TEXT</Radio>
                <Radio value={'CHOICE'}>CHOICE</Radio>
              </Radio.Group>
            )}
          </Form.Item>
          {getFieldValue('aligner_other.type') === 'TEXT' && (
            <Form.Item label="Other Brands">
              {getFieldDecorator('aligner_other.value', {
                rules: [{ required: true, message: 'Please input value!' }],
                initialValue: comparison?.aligner_other?.value || '',
              })(
                <ReactQuill
                  placeholder="Write here"
                  modules={modules}
                  formats={formats}
                />
              )}
            </Form.Item>
          )}
          {getFieldValue('aligner_other.type') === 'CHOICE' && (
            <Form.Item label="Other Brands">
              {getFieldDecorator('aligner_other.value', {
                rules: [{ required: true, message: 'Please choose value!' }],
                initialValue: comparison?.aligner_other?.value || '',
              })(
                <Radio.Group>
                  <Radio value={'CHECK'}>CHECK</Radio>
                  <Radio value={'CROSS'}>CROSS</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          )}

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const ComparisonsCreateEditForm = Form.create({
  name: 'validate_comparisons_edit',
})(ComparisonsCreateEdit);

export default { ComparisonsCreateEdit };
