import React from 'react';

import { Button, Modal } from 'antd';

const ModalCommentBulkUpdate = ({
  visible,
  count,
  onSubmit,
  onHideModal,
  loading,
}) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal
      title={`Approve ${count} Comment`}
      visible={visible}
      centered="true"
      onCancel={onHideModal}
      footer={[
        <Button key="back" onClick={() => onHideModal()} loading={loading}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{
            backgroundColor: '#27AE60',
            border: 'solid 1px #27AE60',
          }}
          loading={loading}
          onClick={() => handleSubmit()}>
          Approve
        </Button>,
      ]}>
      <p>
        You will approve {count} comments. The approved comment will be shown on
        article page. Please check again before you click approve button.
      </p>
    </Modal>
  );
};

export default ModalCommentBulkUpdate;
