import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';

import CampaignApi from 'api/campaigns';
import { alertErr } from 'utils';

const CampaignAdsForm = ({ form }) => {
  const { id } = useParams();
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState({});

  const getDetailCampaign = async () => {
    const { data } = await CampaignApi.detailCampaignAds(id);
    const content = data.data;

    setContent(content);
  };

  const submit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = { ...values };

      if (!err) {
        try {
          setIsSubmitting(true);

          const req = {
            promo_code: payload.promo_code,
            promo_ad_url: payload.promo_ad_url,
          };

          if (id) await CampaignApi.updateCampaignAdsDetail(id, req);
          else await CampaignApi.createCampaignAds(req);

          message.success(`Berhasil ${id ? 'mengubah' : 'membuat'} data`);
          history.push('/cms/campaign-ads');
        } catch (err) {
          alertErr(err);
        } finally {
          setIsSubmitting(false);
        }
      }
    });
  };

  const { getFieldDecorator } = form;

  useEffect(() => {
    id && getDetailCampaign();
  }, [id]);

  return (
    <Layout className="rata-cms-clinic-create-edit">
      <Layout.Header>
        <Typography.Title>
          <Link to="/cms/campaign-ads">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Create'} Campaign
        </Typography.Title>
      </Layout.Header>

      <Layout.Content>
        <Form
          onSubmit={(event) => submit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Ads URL">
            {getFieldDecorator('promo_ad_url', {
              rules: [
                {
                  required: true,
                  message: 'Please input the ads url!',
                },
                {
                  pattern:
                    /^(http|https?:\/\/)?(www\.)?rata\.id\/promotions\/[a-z0-9-]+(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/,
                  message: 'Please enter valid ads url',
                },
              ],
              initialValue: content?.promo_ad_url,
            })(<Input placeholder="Insert ads url" />)}
            <i style={{ fontSize: 12 }}>
              e.g: https://www.rata.id/promotions/t123tr-tahu-tentang-rata-oke
            </i>
          </Form.Item>
          <Form.Item label="Promo Code">
            {getFieldDecorator('promo_code', {
              rules: [
                {
                  required: true,
                  message: 'Please input the promo code!',
                },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Promo Code should not start or end characters with spaces',
                },
              ],
              initialValue: content?.promo_code,
            })(<Input placeholder="Insert ads url" />)}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export const CampaignAdsFormCreateEdit = Form.create({
  name: 'campaign_ads_form',
})(CampaignAdsForm);

export default CampaignAdsForm;
