import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BlogYoutubeApi from 'api/v2/blog/youtube';

import { MONTHS } from 'constants/index';

import { alertErr } from 'utils';

import {
  Button,
  Col,
  Icon,
  Input,
  Layout,
  Table,
  Typography,
  Popconfirm,
  Row,
  Select,
  Switch,
  message,
} from 'antd';

import moment from 'moment';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Youtubes = () => {
  const [blogYoutubes, setBlogYoutubes] = useState([]);
  const [search, setSearch] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);

  const handleDelete = async (id) => {
    try {
      await BlogYoutubeApi.update({ id, is_deleted: true });

      setBlogYoutubes((prevState) =>
        prevState.filter((item) => item.id !== id)
      );
      message.success('Berhasil menghapus youtube');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleChange = async (key, data, id, checkedValue) => {
    try {
      if (key === 'is_priority' && !checkedValue) {
        await BlogYoutubeApi.updatePriority({ id, [key]: !checkedValue });
      } else {
        await BlogYoutubeApi.update({ id, [key]: !checkedValue });
      }

      const temp = data.slice();

      const updatedYoutubes = temp.map((youtube) => {
        if (youtube.id === id) {
          youtube[key] = !youtube[key];
        }

        return youtube;
      });

      manipulateDataWithTableProps(updatedYoutubes);
      message.success('Berhasil mengubah youtube');
    } catch (err) {
      alertErr(err);
    }
  };

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.key = datum.id;

      datum.index_number = index + 1;

      datum.is_highlight_action = (
        <Switch
          checked={datum.is_priority}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_priority', data, datum.id, datum.is_priority)
          }
        />
      );

      datum.is_active_action = (
        <Switch
          checked={datum.is_active}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_active', data, datum.id, datum.is_active)
          }
        />
      );

      datum.date = moment(datum.updated_at).format('DD MMMM YYYY');

      datum.action = (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/reviews-and-promos/youtubes/edit/${datum.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <Popconfirm
              title="Are you sure？"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => handleDelete(datum.id)}>
              <Icon type="delete" />
            </Popconfirm>
          </div>
        </div>
      );
    });

    setBlogYoutubes(data);
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'index_number',
      sorter: (a, b) => a.index_number - b.index_number,
      defaultSortOrder: 'Ascend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Youtube URL',
      dataIndex: 'url',
      sorter: (a, b) => a.url.length - b.url.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Publish Date',
      dataIndex: 'date',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Highlight Top 3',
      dataIndex: 'is_highlight_action',
      key: 'is_highlight_action',
    },
    {
      title: 'Publish',
      dataIndex: 'is_active_action',
      key: 'is_active_action',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const { Search } = Input;
  const { Option } = Select;

  const handleChangeMonth = (value) => setMonthFilter(value);
  const handleChangeStatus = (value) => setStatusFilter(value);

  const handleSearch = async (value) => {
    const { data } = await BlogYoutubeApi.get(value, monthFilter, statusFilter);
    const response = data.data;

    setSearch(value);
    manipulateDataWithTableProps(response);
  };

  const handleFilter = async () => {
    const { data } = await BlogYoutubeApi.get(
      search,
      monthFilter,
      statusFilter
    );
    const response = data.data;

    manipulateDataWithTableProps(response);
  };

  useEffect(() => {
    const getYoutubes = async () => {
      try {
        setLoadingPage(true);
        const { data } = await BlogYoutubeApi.get();
        const response = data.data;

        manipulateDataWithTableProps(response);
      } finally {
        setLoadingPage(false);
      }
    };

    getYoutubes();

    return () => setBlogYoutubes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="rata-cms-review-and-promo-youtubes pd-cms-form-1">
      <Header>
        <Title>Youtube Post</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Link to="/cms/reviews-and-promos/youtubes/create">
              <Button type="primary">Add Youtube Post</Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={11}>
            <div className="label">Search by</div>
            <Search onSearch={(value) => handleSearch(value)} />
          </Col>
          <Col xs={13}>
            <div className="label">Filter by</div>

            <Row gutter={[12, 0]}>
              <Col xs={8}>
                <Select
                  defaultValue="All Month"
                  onChange={handleChangeMonth}
                  style={{ width: '100%' }}>
                  {MONTHS.map((month, id) => (
                    <Option key={id} value={month.number}>
                      {month.name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xs={8}>
                <Select
                  defaultValue="All Status"
                  onChange={handleChangeStatus}
                  style={{ width: '100%' }}>
                  <Option value="">All Status</Option>
                  <Option value="true">Active</Option>
                  <Option value="false">Unactive</Option>
                </Select>
              </Col>

              <Col xs={8}>
                <Button
                  onClick={handleFilter}
                  type="primary"
                  ghost
                  style={{ width: '100%' }}>
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={blogYoutubes}
              columns={columns}
              loading={loadingPage}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Youtubes;
