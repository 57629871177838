import axios from 'axios';

export const createAPI = (
  baseURL = `${process.env.REACT_APP_API_URL}/v1/cms`,
  config = {}
) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('cms_token') || ''}`,
    },
    ...config,
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if ([401, 403].includes(error.response.status)) {
        if (alert('Session anda telah berakhir. Mohon login kembali.')) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      return Promise.reject(error);
    }
  );

  // setup axios.intercept
  return axiosInstance;
};

const api = createAPI();

export default api;
