import api from './index';

export default {
  createAdmin(payload) {
    return api.post(`/admins`, { request: payload });
  },
  listAdmin(page) {
    return api.get(`/admins?pagination=true&page=${page}&size=10`);
  },
  updateAdmin(payload) {
    return api.put(`/admins/${payload.id}`, { request: payload });
  },
  adminUpdate(payload) {
    return api.get(`/admins/update`, { request: payload });
  },
  changeAdminPassword(payload) {
    return api.post(`/admins/change_password`, payload);
  },
  deleteAdmin(id) {
    return api.delete(`/admins/${id}`);
  },
};
