import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import ColorPicker from 'rc-color-picker';

import {
  Button,
  Input,
  Layout,
  Form,
  Typography,
  Select,
  message,
  Col,
  Row,
  Icon,
} from 'antd';

import { PdUploader } from 'components/pd-uploader';

import ClinicApi from 'api/clinic';

import { get } from 'lodash';

import { FIELDS } from './constant';

import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const ClinicCreateEdit = ({ handleContent, form, content }) => {
  const { id } = useParams();
  let history = useHistory();

  const [clinicLocations, setClinicLocations] = useState([]);
  const [mapImages, setMapImages] = useState({
    media_url: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      delete values.button_map_background_color;
      delete values.button_map_font_color;
      delete values.button_wa_background_color;
      delete values.button_wa_font_color;

      const payload = {
        ...content,
        ...values,
        ...mapImages,
      };

      if (!err) {
        try {
          setLoadingButton(true);

          if (id) {
            const { data } = await ClinicApi.update(payload, id);

            if (data.success) {
              message.success('Berhasil mengubah klinik');

              history.push('/cms/clinics/manage');
            }
          } else {
            const { data } = await ClinicApi.create(payload);

            if (data.success) {
              message.success('Berhasil menambah klinik');

              history.push('/cms/clinics/manage');
            }
          }
        } catch (err) {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  const handleMapImages = (key, value) => {
    setMapImages((prevState) => ({ ...prevState, [key]: value }));
  };

  const { getFieldDecorator, getFieldsError } = form;

  const hasErrors = (fieldsError) =>
    Object.keys(fieldsError).some((field) => fieldsError[field]);

  const onChange = (val, key) => {
    handleContent(val, key);
  };

  const generateLink = () => {
    const number = content[FIELDS.TEMPORARY_WHATSAPP_NUMBER];
    const message = content[FIELDS.TEMPORARY_WHATSAPP_MESSAGE].replaceAll(
      ' ',
      '%20'
    );
    const url = 'https://api.whatsapp.com';
    const end_url = `${url}/send?phone=${number}&text=${message}`;

    onChange(end_url, [FIELDS.BUTTON_WHATSAPP_LINK]);
  };

  useEffect(() => {
    handleMapImages(FIELDS.MEDIA_URL, content[FIELDS.MEDIA_URL]);
  }, [content]);

  useEffect(() => {
    const getClinicLocations = async () => {
      const { data } = await ClinicApi.getLocation();
      const clinics = data.data;

      setClinicLocations(clinics);
    };

    getClinicLocations();
  }, []);

  return (
    <Layout className="rata-cms-clinic-create-edit">
      <Header>
        <Title>
          <Link to="/cms/clinics/manage">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Create New'} Clinic
        </Title>
      </Header>

      <Content>
        <Form
          onSubmit={(event) => submit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Location">
            {getFieldDecorator(FIELDS.LOCATION_ID, {
              rules: [{ required: true, message: 'Please input the name!' }],
              initialValue: content[FIELDS.LOCATION_ID],
            })(
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Pilih lokasi klinik"
                optionFilterProp="children"
                onChange={(val) => onChange(val, FIELDS.LOCATION_ID)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {clinicLocations.length > 0 &&
                  clinicLocations.map((location) => (
                    <Option value={location.id} key={location.id}>
                      {location.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Clinic Name">
            {getFieldDecorator(FIELDS.NAME, {
              rules: [
                {
                  required: true,
                  message: 'Please input the name!',
                },
              ],
              initialValue: content[FIELDS.NAME],
            })(<Input placeholder="Insert name" />)}
          </Form.Item>

          <Row gutter={[16, 0]}>
            <Col xs={12}>
              <Form.Item label="Desktop Image (292px x 250px)">
                <PdUploader
                  purpose={FIELDS.DESKTOP_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.DESKTOP_MEDIA_URL, '') ||
                    content[FIELDS.DESKTOP_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.DESKTOP_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Desktop Image ALT Text">
                {getFieldDecorator(FIELDS.DESKTOP_MEDIA_URL_ALT, {
                  initialValue: content[FIELDS.DESKTOP_MEDIA_URL_ALT],
                })(<Input placeholder="Insert Desktop Image ALT Text" />)}
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Form.Item label="Mobile Image (183px x 178px)">
                <PdUploader
                  purpose={FIELDS.MOBILE_MEDIA_URL}
                  imgUrl={
                    get(mapImages, FIELDS.MOBILE_MEDIA_URL, '') ||
                    content[FIELDS.MOBILE_MEDIA_URL]
                  }
                  afterUpload={(url) => {
                    handleMapImages([FIELDS.MOBILE_MEDIA_URL], url);
                  }}
                />
              </Form.Item>

              <Form.Item label="Mobile Image ALT Text">
                {getFieldDecorator(FIELDS.MOBILE_MEDIA_URL_ALT, {
                  initialValue: content[FIELDS.MOBILE_MEDIA_URL_ALT],
                })(<Input placeholder="Insert Mobile Image ALT Text" />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Clinic Address">
            {getFieldDecorator(FIELDS.ADDRESS, {
              rules: [
                {
                  required: true,
                  message: 'Please input the address!',
                },
              ],
              initialValue: content[FIELDS.ADDRESS],
            })(<Input placeholder="Insert address" />)}
          </Form.Item>

          <Form.Item label="Clinic Address URL">
            {getFieldDecorator(FIELDS.BUTTON_MAP_LINK, {
              rules: [
                {
                  required: true,
                  message: 'Please input the address url!',
                },
              ],
              initialValue: content[FIELDS.BUTTON_MAP_LINK],
            })(<Input placeholder="Insert address url" />)}
          </Form.Item>

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_MAP_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_MAP_BACKGROUND_COLOR),
              initialValue: content[FIELDS.BUTTON_MAP_BACKGROUND_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_MAP_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_MAP_FONT_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_MAP_FONT_COLOR),
              initialValue: content[FIELDS.BUTTON_MAP_FONT_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_MAP_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Clinic Phone Number">
            {getFieldDecorator(FIELDS.PHONE, {
              initialValue: content[FIELDS.PHONE] || '',
            })(<Input placeholder="Insert clinic phone" />)}
          </Form.Item>

          <Row gutter={[16, 0]}>
            <Col xs={8}>
              <Form.Item label="Generate Whatsapp Number">
                <Input
                  value={get(content, FIELDS.TEMPORARY_WHATSAPP_NUMBER, '')}
                  placeholder="Input whatsapp number"
                  onChange={(event) =>
                    onChange(
                      event.target.value,
                      FIELDS.TEMPORARY_WHATSAPP_NUMBER
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={16}>
              <Form.Item label="Generate Whatsapp Message">
                <Input
                  value={get(content, FIELDS.TEMPORARY_WHATSAPP_MESSAGE, '')}
                  placeholder="Input whatsapp message"
                  onChange={(event) =>
                    onChange(
                      event.target.value,
                      FIELDS.TEMPORARY_WHATSAPP_MESSAGE
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={12}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => generateLink()}>
                Generate
              </Button>
            </Col>
          </Row>

          <Form.Item label="Whatsapp URL">
            {getFieldDecorator(FIELDS.BUTTON_WHATSAPP_LINK, {
              rules: [
                {
                  required: true,
                  message: 'Please input whatsapp url!',
                },
              ],
              initialValue: content[FIELDS.BUTTON_WHATSAPP_LINK],
            })(<Input placeholder="Insert whatsapp url" />)}
          </Form.Item>

          <Form.Item label="Button Color">
            {getFieldDecorator(FIELDS.BUTTON_WHATSAPP_BACKGROUND_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_WHATSAPP_BACKGROUND_COLOR),
              initialValue: content[FIELDS.BUTTON_WHATSAPP_BACKGROUND_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_WHATSAPP_BACKGROUND_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item label="Button Font Color">
            {getFieldDecorator(FIELDS.BUTTON_WHATSAPP_FONT_COLOR, {
              getValueFromEvent: (e) =>
                onChange(e.color, FIELDS.BUTTON_WHATSAPP_FONT_COLOR),
              initialValue: content[FIELDS.BUTTON_WHATSAPP_FONT_COLOR],
            })(
              <ColorPicker
                animation="slide-up"
                color={content[FIELDS.BUTTON_WHATSAPP_FONT_COLOR]}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}
              disabled={hasErrors(getFieldsError())}>
              Publish
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export const ClinicForm = Form.create({
  name: 'clinic_create_edit_form',
})(ClinicCreateEdit);

export default ClinicCreateEdit;
