import api from './index';

export default {
  get() {
    return api.get(`quiz/answers`);
  },

  create(payload) {
    return api.post(`quiz/answers`, { request: payload });
  },

  getNew() {
    return api.get(`users/quizzes`);
  },

  createQuizzes(payload) {
    return api.post(`users/quizzes`, { request: payload });
  },
};
