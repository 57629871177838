import api from './index';

export default {
  createQuestion(payload) {
    return api.post(`/questions`, { request: payload });
  },
  listQuestions() {
    return api.get(`/questions`);
  },
  getQuestion(id) {
    return api.get(`/questions/${id}`);
  },
  updateQuestionDetail(id, payload) {
    return api.put(`/questions/${id}`, { request: payload });
  },
  updateQuestionBulk(payload) {
    return api.put(`/questions/bulk`, { request: payload });
  },
  updateQuestionsOrder(payload) {
    return api.put(`/questions`, { questions: payload });
  },
  deleteQuestion(id) {
    return api.delete(`/questions/${id}`);
  },
};
