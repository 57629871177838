import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import FormServices from 'api/forms';

import { Col, Layout, Table, Typography, Row, Icon } from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Answers = () => {
  const history = useHistory();
  const { formId, resultId } = useParams();

  const [answers, setAnswers] = useState([]);
  const [nameAnswer, setNameAnswer] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);

  const queries = {
    formId,
    resultId,
  };

  const getAllAnswers = async () => {
    try {
      const response = await FormServices.getAnswerUser(queries);

      const { data } = response.data;

      const findUser = data.find((user) =>
        ['name', 'nama'].includes(user.question.toLowerCase())
      );

      setAnswers(data);
      setNameAnswer(findUser.answer);
    } finally {
      setLoadingPage(false);
    }
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
    },
    // {
    //   title: 'Extra',
    //   dataIndex: 'extra',
    //   key: 'extra',
    //   render: (_, record) => <>{record.extra ? 'Yes' : 'No'}</>,
    // },
  ];

  useEffect(() => {
    getAllAnswers();

    return () => setAnswers();
  }, []);

  return (
    <Layout className="rata-cms-forms-answers pd-cms-form-1">
      <Header>
        <Title style={{ textTransform: 'capitalize' }}>
          <Icon type="caret-left" onClick={() => history.goBack()} />
          {nameAnswer} Answer
          {answers?.length > 0 ? ` - ${answers[0].form_purpose}` : null}
        </Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Table
              dataSource={answers}
              columns={columns}
              pagination={false}
              rowKey="id"
              loading={loadingPage}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Answers;
