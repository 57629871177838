import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import 'rc-color-picker/assets/index.css';

import AuthenticationContext from './contexts/authentication';
import CommentContext from './contexts/comment';

import { LoginPageForm as LoginPage } from 'pages/login';

import AuthenticatedGuardRoute from 'components/guard/authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';

import Dashboard from 'pages/cms/dashboard';

// ContentManagement
import { PromoBanner } from 'pages/cms/home/promo-banner';
import { PromoBannerEditForm as HomePromoBannerEdit } from 'pages/cms/home/promo-banner/edit';
import { PricingForm } from 'pages/v2/cms/home/pricing';
import { HomeProcedureForm as HomeProcedure } from 'pages/v2/cms/home/procedure';
import { Testimonials as HomeTestimonials } from 'pages/v2/cms/home/testimonials';
import { TestimonialsCreateEditForm as HomeTestimonialEdit } from 'pages/v2/cms/home/testimonials/createEdit';
import { ComparisonsSectionForm } from 'pages/cms/comparisons/section';
import { Comparisons } from 'pages/cms/comparisons';
import { ComparisonsCreateEditForm } from 'pages/cms/comparisons/createEdit';
import { BenefitSectionForm } from 'pages/cms/benefits/section';
import { Benefits } from 'pages/cms/benefits';
import { BenefitsCreateEditForm } from 'pages/cms/benefits/createEdit';
import { KOLSectionForm } from 'pages/cms/kols/section';
import { Kols } from 'pages/cms/kols';
import { KolsCreateEditForm } from 'pages/cms/kols/createEdit';
import { TestimoniesSectionForm } from 'pages/cms/testimonies/section';
import { Testimonies } from 'pages/cms/testimonies';
import { TestimoniesCreateEditForm } from 'pages/cms/testimonies/createEdit';
import { ComparisonContentForm as HomeComparison } from 'pages/v2/cms/home/comparison';
import { LocationForm as LocationLocation } from 'pages/v2/cms/home/location';
import { DoctorForm as Doctor } from 'pages/v2/cms/home/doctor';
import { DoctorCreateEditForm as DoctorCreateEdit } from 'pages/v2/cms/home/doctor/createEdit';
import { PromotionCreate } from 'pages/v2/cms/promotions';
import Campaigns from 'pages/cms/campaigns';
import { CampaignAdsFormCreateEdit } from 'pages/cms/campaigns/createEdit';
import CampaignRecords from 'pages/cms/campaigns/records';

import QuizAnswers from 'pages/v3/cms/quiz-answers';
import QuizAnswersNew from 'pages/v3/cms/quiz-answers-new';
import { QuizIntroContentForm as QuizIntroContent } from 'pages/v3/cms/quiz-intro-contents';
import { QuizResultForm as QuizResult } from 'pages/v3/cms/quiz-results';

import Articles from 'pages/v3/cms/articles';
import ArticlesCreateEdit from 'pages/v3/cms/articles/createEdit';
import ArticleHistories from 'pages/v3/cms/articles/history';

import Comments from 'pages/cms/comments';

import Clinics from 'pages/cms/clinics';
import ClinicHistories from 'pages/cms/clinics/history';
import ClinicsSEO from 'pages/cms/clinics/seo';
import ClinicsForm from 'pages/cms/clinics/createEdit';

import Promos from 'pages/v2/cms/review-and-promo/promos';
import PromosCreateEdit from 'pages/v2/cms/review-and-promo/promos/createEdit';

import Banners from 'pages/v2/cms/review-and-promo/banners';
import BannersCreateEdit from 'pages/v2/cms/review-and-promo/banners/createEdit';

import StaticPosts from 'pages/v2/cms/review-and-promo/static-posts';
import StaticPostsCreateEdit from 'pages/v2/cms/review-and-promo/static-posts/createEdit';

import News from 'pages/v2/cms/review-and-promo/news';
import NewsCreateEdit from 'pages/v2/cms/review-and-promo/news/createEdit';

import Reviews from 'pages/v2/cms/review-and-promo/reviews';
import ReviewsCreateEdit from 'pages/v2/cms/review-and-promo/reviews/createEdit';

import Results from 'pages/v2/cms/review-and-promo/results';
import ResultsCreateEdit from 'pages/v2/cms/review-and-promo/results/createEdit';

import Stories from 'pages/v2/cms/review-and-promo/stories';
import StoriesCreateEdit from 'pages/v2/cms/review-and-promo/stories/createEdit';

import Youtubes from 'pages/v2/cms/review-and-promo/youtubes';
import YoutubesCreateEdit from 'pages/v2/cms/review-and-promo/youtubes/createEdit';

import BlogFAQ from 'pages/v2/cms/review-and-promo/faqs';
import FAQsForm from 'pages/v2/cms/review-and-promo/faqs/createEdit';

import { GenerateLinkForm } from 'pages/cms/generator';

import { PromotionCreateEditForm as PromotionForm } from 'pages/v2/cms/promotions/createEdit';
import PromotionHistories from 'pages/v2/cms/promotions/history';
import { LocationEditForm as LocationLocationEdit } from 'pages/v2/cms/home/location/createEdit';
import { PrivacyPolicyForm } from 'pages/cms/terms-and-conditions/privacy_policy';
import { TermsForm } from 'pages/cms/terms-and-conditions/terms';
import { AdminList } from 'pages/cms/admin';
import { AdminForm } from 'pages/cms/admin/createEdit';
import { AdminChangePasswordForm as AdminChangePassword } from 'pages/cms/admin/changePassword';
import { RataEduForm as RataEdu } from 'pages/cms/rata-edu';
import { ApaItuRataBannerForm as ApaItuRataEdit } from 'pages/v2/cms/apa-itu-rata/banner';
import { VideoSectionForm } from 'pages/v2/cms/apa-itu-rata/video-section';
import { FAQ } from 'pages/v2/cms/apa-itu-rata/faq';
import { FAQsForm as FAQEdit } from 'pages/v2/cms/apa-itu-rata/faq/createEdit';
import { CaraKonsultasiBannerForm } from 'pages/v2/cms/cara-konsultasi/banner';
import { CaraKonsultasi } from 'pages/v2/cms/cara-konsultasi/cara-konsultasi';
import { CaraKonsultasiForm } from 'pages/v2/cms/cara-konsultasi/cara-konsultasi/createEdit';
import { ProtokolCovidForm } from 'pages/v2/cms/protokol-covid';
import { UntukDokterForm } from 'pages/v2/cms/untuk-dokter';
import { GeneralContentForm } from 'pages/v2/cms/general-content';
import UserCampaigns from 'pages/cms/users/campaigns';

import { FloatingPromoBannerForm } from 'pages/v3/cms/floating-promo-banners';
import OurOffers from 'pages/v3/cms/our-offers';
import OurOfferHistories from 'pages/v3/cms/our-offers/history';
import SectionForm from 'pages/v3/cms/form-section/forms';
import Questions from 'pages/v3/cms/form-section/questions';
import ResultsForm from 'pages/v3/cms/form-section/results';
import AnswerForm from 'pages/v3/cms/form-section/answers';
import { TrackingEvents } from 'pages/v3/cms/tracking-events';
import { OurOfferForm } from 'pages/v3/cms/our-offers/createEdit';
import { NewsletterSectionForm } from 'pages/v3/cms/newsletter-section';
import { CreateEditFormAppointment } from 'pages/v3/cms/form-section/forms/createEdit';
import { CreateEditFormQuestionAppointment } from 'pages/v3/cms/form-section/questions/createEdit';
import FormHistories from 'pages/v3/cms/form-section/forms/history';

import { Sidebar } from 'components/cms-sidebar';

import 'antd/dist/antd.css';
import './App.scss';

function ContentManagement() {
  return (
    <Fragment>
      <Switch>
        <NotAuthenticatedGuardRoute exact path="/" component={LoginPage} />;
        <Layout className="pd-cms">
          <Sidebar />
          <AuthenticatedGuardRoute
            exact
            path="/cms/dashboard"
            component={Dashboard}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/banners"
            component={PromoBanner}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/banners/:id"
            component={HomePromoBannerEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/prosedur"
            component={HomeProcedure}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/pricing"
            component={PricingForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/testimonials"
            component={HomeTestimonials}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/testimonials/edit/:id"
            component={HomeTestimonialEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/testimonials/create"
            component={HomeTestimonialEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/comparisons/section"
            component={ComparisonsSectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/comparisons/manage"
            component={Comparisons}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/comparisons/create"
            component={ComparisonsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/comparisons/edit/:id"
            component={ComparisonsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/benefits/section"
            component={BenefitSectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/benefits/manage"
            component={Benefits}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/benefits/create"
            component={BenefitsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/benefits/edit/:id"
            component={BenefitsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/kols/section"
            component={KOLSectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/kols/manage"
            component={Kols}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/kols/create"
            component={KolsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/kols/edit/:id"
            component={KolsCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/testimonies/section"
            component={TestimoniesSectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/testimonies/manage"
            component={Testimonies}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/testimonies/create"
            component={TestimoniesCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/testimonies/edit/:id"
            component={TestimoniesCreateEditForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/comparison"
            component={HomeComparison}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/rata-edu"
            component={RataEdu}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/quiz/edit"
            component={QuizIntroContent}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/quiz/result-a"
            component={QuizResult}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/quiz/reports"
            component={QuizAnswers}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/quiz/reports/new"
            component={QuizAnswersNew}
          />

          {/* Clinics */}
          <AuthenticatedGuardRoute
            exact
            path="/cms/clinics/manage"
            component={Clinics}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/clinics/manage/:id/history"
            component={ClinicHistories}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/clinics/seo"
            component={ClinicsSEO}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/clinics/manage/create"
            component={ClinicsForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/clinics/manage/edit/:id"
            component={ClinicsForm}
          />
          {/* end of Clinics */}

          <AuthenticatedGuardRoute
            exact
            path="/cms/apa-itu-rata/banner"
            component={ApaItuRataEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/apa-itu-rata/video"
            component={VideoSectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/apa-itu-rata/faq"
            component={FAQ}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/apa-itu-rata/faq/:page"
            component={FAQEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/cara-konsultasi/banner"
            component={CaraKonsultasiBannerForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/cara-konsultasi/manage"
            component={CaraKonsultasi}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/cara-konsultasi/manage/:page"
            component={CaraKonsultasiForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/untuk-dokter"
            component={UntukDokterForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/promotions"
            component={PromotionCreate}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/promotions/:id"
            component={PromotionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/promotions/:id/history"
            component={PromotionHistories}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/campaign-ads"
            component={Campaigns}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/campaign-ads/create"
            component={CampaignAdsFormCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/campaign-ads/edit/:id"
            component={CampaignAdsFormCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/campaign-ads/:promo_code/records"
            component={CampaignRecords}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/users/campaigns/:campaign_id"
            component={UserCampaigns}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/newsletter-section"
            component={NewsletterSectionForm}
          />

          {/* Forms Section*/}
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms"
            component={SectionForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:id/history"
            component={FormHistories}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/create"
            component={CreateEditFormAppointment}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/edit/:id"
            component={CreateEditFormAppointment}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:formId/questions"
            component={Questions}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:formId/questions/create"
            component={CreateEditFormQuestionAppointment}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:formId/questions/edit/:questionId"
            component={CreateEditFormQuestionAppointment}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:formId/results"
            component={ResultsForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/forms/:formId/answers/:resultId"
            component={AnswerForm}
          />

          {/* Artiles */}
          <AuthenticatedGuardRoute
            exact
            path="/cms/articles"
            component={Articles}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/articles/create"
            component={ArticlesCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/articles/edit/:id"
            component={ArticlesCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/articles/:id/history"
            component={ArticleHistories}
          />
          {/* end of Articles */}

          {/* Comments */}
          <AuthenticatedGuardRoute
            exact
            path="/cms/comments/:status"
            component={Comments}
          />
          {/* end of Comments */}

          {/* Review & Promo Post */}
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/promos"
            component={Promos}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/promos/create"
            component={PromosCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/promos/edit/:id"
            component={PromosCreateEdit}
          />
          {/* end of Review & Promo Post */}

          {/* Review & Promo Banner */}
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/banners"
            component={Banners}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/banners/create"
            component={BannersCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/banners/edit/:id"
            component={BannersCreateEdit}
          />
          {/* end of Review & Promo Banner */}

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/static-posts"
            component={StaticPosts}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/static-posts/edit/:id"
            component={StaticPostsCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/rata-updates"
            component={News}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/rata-updates/create"
            component={NewsCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/rata-updates/edit/:id"
            component={NewsCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/reviews"
            component={Reviews}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/reviews/edit/:id"
            component={ReviewsCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/reviews/create"
            component={ReviewsCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/results"
            component={Results}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/results/create"
            component={ResultsCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/results/edit/:id"
            component={ResultsCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/stories"
            component={Stories}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/stories/create"
            component={StoriesCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/stories/edit/:id"
            component={StoriesCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/youtubes"
            component={Youtubes}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/youtubes/create"
            component={YoutubesCreateEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/youtubes/edit/:id"
            component={YoutubesCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/faqs"
            component={BlogFAQ}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/faqs/create"
            component={FAQsForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/reviews-and-promos/faqs/edit/:id"
            component={FAQsForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/protokol-covid"
            component={ProtokolCovidForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/home/doctors"
            component={Doctor}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/doctors/:page"
            component={DoctorCreateEdit}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/home/location"
            component={LocationLocation}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/home/location/:page"
            component={LocationLocationEdit}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/our-offers"
            component={OurOffers}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/our-offers/:id/history"
            component={OurOfferHistories}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/our-offers/edit/:id"
            component={OurOfferForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/our-offers/create"
            component={OurOfferForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/general-content"
            component={GeneralContentForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/floating-promo-banners"
            component={FloatingPromoBannerForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/tnc/kebijakan-privasi"
            component={PrivacyPolicyForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/tnc/ketentuan-penggunaan"
            component={TermsForm}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/tracking/events"
            component={TrackingEvents}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/admin"
            component={AdminList}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/admin/edit/change-password"
            component={AdminChangePassword}
          />
          <AuthenticatedGuardRoute
            exact
            path="/cms/admin/:page"
            component={AdminForm}
          />

          <AuthenticatedGuardRoute
            exact
            path="/cms/generator"
            component={GenerateLinkForm}
          />
        </Layout>
      </Switch>
    </Fragment>
  );
}

function App() {
  return (
    <AuthenticationContext>
      <CommentContext>
        <Router>
          <ContentManagement />
        </Router>
      </CommentContext>
    </AuthenticationContext>
  );
}

export default App;
