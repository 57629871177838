import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
} from 'antd';

import moment from 'moment';

import ReportApi from 'api/report';
import QuizAnswersApi from 'api/v3/quiz_answer';

const { Header, Content } = Layout;
const { Title } = Typography;

const QuizAnswers = () => {
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telp',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Pernah Pake Behel?',
      dataIndex: 'is_ever',
      render: (is_ever) => <div>{is_ever ? 'Ya' : 'Tidak'}</div>,
    },
    {
      title: 'Tipe Gigi',
      dataIndex: 'teeth_type',
      key: 'teeth_type',
    },
    {
      title: 'Kepuasan Gigi',
      dataIndex: 'is_teeth_satesfied',
      key: 'is_teeth_satesfied',
      render: (is_teeth_satesfied) => (
        <div>{is_teeth_satesfied ? 'Ya' : 'Tidak'}</div>
      ),
    },
    {
      title: 'Mau Meratakan?',
      dataIndex: 'is_want_to',
      key: 'is_want_to',
      render: (is_want_to) => <div>{is_want_to ? 'Ya' : 'Tidak'}</div>,
    },
    {
      title: 'Domisili',
      dataIndex: 'location',
      key: 'location',
    },
  ];

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.number = index + 1;

      datum['Data Created'] = moment(datum.created_at).format('DD/MMMM/YYYY');
      datum['Time Created'] = moment(datum.created_at).format('hh:mm:ss');
    });

    setAnswers(data);
  };

  const exportToCSV = async () => {
    const { data } = await ReportApi.oldQuiz();

    const link = document.createElement('a');
    link.href = data.data.url;
    link.download = data.data.file_name;
    link.click();

    message.success('Data berhasil diunduh, mohon tunggu');
  };

  useEffect(() => {
    const getQuizAnswers = async () => {
      try {
        setIsLoading(true);

        const { data } = await QuizAnswersApi.get();
        const answers = data.data;

        manipulateDataWithTableProps(answers);
      } catch {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      } finally {
        setIsLoading(false);
      }
    };

    getQuizAnswers();
  }, []);

  return (
    <Layout className="rata-cms-quiz-answers pd-cms-form-1">
      <Header>
        <Title>Quiz Report</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table dataSource={answers} columns={columns} loading={isLoading} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default QuizAnswers;
