import api from './index';
import apiV1 from '../index';

export default {
  updatePageContent(payload) {
    return apiV1.put(`/testimony_pages`, { request: payload });
  },
  getPageContent() {
    return apiV1.get(`/testimony_pages`);
  },
  createTestimonies(payload) {
    return api.post(`/testimonies`, { request: payload });
  },
  testimoniesList(page, size) {
    return api.get(`/testimonies?page=${page}&size=${size}`);
  },
  getDetailTestimony(id) {
    return api.get(`/testimonies/${id}`);
  },
  updateTestimonies(id, payload) {
    return api.put(`/testimonies/${id}`, { request: payload });
  },
  updateOrderList(payload) {
    return api.put(`/testimonies`, { request: payload });
  },
  deleteTestimony(id) {
    return api.delete(`/testimonies/${id}`);
  },
};
