import React, { useEffect, useState } from 'react';

import CommentSummary from 'components/Comment/Summary';

import { Input, Modal } from 'antd';

const ModalCommentChangeStatus = ({
  visible,
  onSubmit,
  hideModal,
  comment,
  status,
}) => {
  const [content, setContent] = useState({
    reason: null,
    title: '',
    content: '',
  });

  const handleContent = (key, value) => {
    setContent((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = () => {
    onSubmit(content.reason);
  };

  useEffect(() => {
    if (!visible) {
      setContent({
        reason: null,
        title: '',
        content: '',
      });
    }
  }, [visible]);

  useEffect(() => {
    switch (status) {
      case 'ADMIN_RATA':
        handleContent('title', 'Mark as Marketing Comment');
        handleContent(
          'content',
          'You will mark this comment as a Marketing Comment. The marked comment will be shown on article page. Please check again before you click approve button.'
        );
        break;
      case 'REJECTED':
        handleContent('title', 'Reject Comment');
        handleContent(
          'content',
          'You will reject this replies. The rejected replies will be automatically hidden by system on the article page.'
        );
        break;
      case 'APPROVED':
        handleContent('title', 'Approve Comment');
        handleContent(
          'content',
          'You will approve this comment. The approved comment will be shown on article page. Please check again before you click approve button.'
        );
        break;
      default:
        return;
    }
  }, [status]);

  return (
    <Modal
      title={content['title']}
      visible={visible}
      onOk={handleSubmit}
      centered="true"
      onCancel={hideModal}>
      <p>{content['content']}</p>
      <CommentSummary comment={comment} />

      {status === 'REJECTED' && (
        <div className="mt-10">
          <p>Reject Reason</p>
          <Input
            placeholder="Insert Reject Reason"
            value={content['reason']}
            onChange={(e) => handleContent('reason', e.target.value)}
          />
        </div>
      )}
    </Modal>
  );
};

export default ModalCommentChangeStatus;
