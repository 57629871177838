import React, { useContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';

import {
  Checkbox,
  Layout,
  Select,
  Typography,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  message,
  Pagination,
} from 'antd';

import ArticleCategoryApi from 'api/v3/article_category';
import CommentyApi from 'api/comment';

import { CommentContext } from 'contexts/comment';

import CommentSummary from 'components/Comment/Summary';
import ModalCommentChangeStatus from 'components/modal/comment-change-status';
import ModalCommentBulkUpdate from 'components/modal/comment-bulk-update';

import { PageSpinner } from 'components/page-spinner';

import { FIELDS } from './constant';

const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Comments = ({ match }) => {
  const { status } = match.params;

  const { getArticleCounters } = useContext(CommentContext || {});

  const [loadingPage, setLoadingPage] = useState(true);
  const [articleCategories, setArticleCategories] = useState([]);
  const [body, setBody] = useState({
    [FIELDS.STATUS]: status.toUpperCase(),
    [FIELDS.CATEGORY_KEY]: '',
    [FIELDS.QUERY]: '',
    [FIELDS.START_DATE]: '',
    [FIELDS.END_DATE]: '',
    page: 1,
    size: 10,
    total_data: 0,
  });
  const [comments, setComments] = useState([]);
  const [isDialog, setIsDialog] = useState({
    active: false,
    status: '',
  });
  const [dialogBulkUpdate, setDialogBulkUpdate] = useState(false);
  const [commentSelected, setCommentSelected] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleBody = (key, value) => {
    setBody((prevState) => ({ ...prevState, [key]: value }));
  };

  const onChangePage = (value) => {
    handleBody('page', value);
  };

  const convertObjToQs = (obj) => {
    return Object.keys(obj)
      .filter((key) => obj[key] !== '')
      .map((key) => `${key}=${obj[key]}`)
      .join('&');
  };

  const handleSearch = async (value) => {
    setBody((prevState) => ({ ...prevState, [FIELDS.QUERY]: value }));
  };

  const onChangeDate = (date, dateString) => {
    setBody((prevState) => ({
      ...prevState,
      [FIELDS.START_DATE]: dateString[0],
    }));
    setBody((prevState) => ({
      ...prevState,
      [FIELDS.END_DATE]: dateString[1],
    }));
  };

  const onCheckedAll = (e) => {
    if (!e.target.checked) {
      setSelected([]);
      return;
    }

    const mapCommentId = comments.map((comment) => comment.id);
    setSelected(mapCommentId);
  };

  const onChecked = (id) => {
    if (selected.includes(id)) {
      const filter = selected.filter((item) => item !== id);
      setSelected(filter);
    } else {
      setSelected((prevState) => prevState.concat(id));
    }
  };

  const onChangeFilter = (value, key) => {
    setBody((prevState) => ({ ...prevState, [key]: value }));
  };

  const fetchComments = async () => {
    setLoadingPage(true);

    try {
      const qs = convertObjToQs(body);

      const { data } = await CommentyApi.get(qs);

      handleBody('total_data', data.total_data);

      setComments([...data.data]);
    } catch {
      message.error('Tidak dapat menghubungi server, cek koneksi');
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = async () => {
    handleBody('page', 1);
    fetchComments();
  };

  const onChangeStatus = async (status, item) => {
    setIsDialog({
      active: true,
      status,
    });

    setCommentSelected(item);
  };

  const onOpenBulkUpdateDialog = () => {
    setDialogBulkUpdate(true);
  };

  const onBulkUpdate = async () => {
    try {
      setLoadingSubmit(true);

      const payload = selected.map((id) => ({
        id,
        admin_id: localStorage.getItem('current_admin_id'),
        status: 'APPROVED',
        reason: null,
      }));

      await CommentyApi.bulkUpdate(payload);

      message.success('Comment successfully approved!');

      fetchComments();

      getArticleCounters();

      setSelected([]);
    } catch {
      message.error('Terjadi gangguan, mohon cek koneksi internet Anda');
    } finally {
      setDialogBulkUpdate(false);
      setLoadingSubmit(false);
    }
  };

  const onSubmitChangeStatus = async (reason) => {
    try {
      const payload = {
        admin_id: localStorage.getItem('current_admin_id'),
        status: isDialog['status'],
        reason,
      };

      await CommentyApi.update(commentSelected.id, payload);

      if (isDialog['status'] === 'ADMIN_RATA') {
        message.success('Comment successfully marked as Marketing Comment!');
      }

      if (isDialog['status'] === 'REJECTED') {
        message.error('Comment successfully rejected');
      }

      if (isDialog['status'] === 'APPROVED') {
        message.success('Comment successfully approved!');
      }

      setIsDialog({
        active: false,
        status: '',
      });

      fetchComments();

      setSelected([]);
    } catch (e) {
      const errMessage = e.response.data.message;

      if (errMessage) {
        message.error(errMessage);
      }
    }
  };

  useEffect(() => {
    setBody(() => ({
      [FIELDS.STATUS]: status.toUpperCase(),
      [FIELDS.CATEGORY_KEY]: '',
      [FIELDS.QUERY]: '',
      [FIELDS.START_DATE]: '',
      [FIELDS.END_DATE]: '',
      page: 1,
      size: 10,
      total_data: 0,
    }));

    setSelected([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    fetchComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body[FIELDS.STATUS]]);

  useEffect(() => {
    fetchComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body.page]);

  useEffect(() => {
    const getArticleCategories = async () => {
      const { data } = await ArticleCategoryApi.get();
      const categories = data.data;

      setArticleCategories(categories);
    };

    fetchComments();
    getArticleCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="pd-cms-form-1">
      <ModalCommentChangeStatus
        comment={commentSelected}
        status={isDialog['status']}
        visible={isDialog['active']}
        hideModal={() =>
          setIsDialog({
            status: '',
            active: false,
          })
        }
        onSubmit={onSubmitChangeStatus}
      />
      <ModalCommentBulkUpdate
        count={selected.length}
        visible={dialogBulkUpdate}
        onHideModal={() => setDialogBulkUpdate(false)}
        onSubmit={onBulkUpdate}
        loading={loadingSubmit}
      />

      <Header>
        <Title>Comments</Title>
      </Header>

      <Content>
        <Row gutter={[24, 0]}>
          <Col xs={6}>
            <label className="label">Category</label>
            <Select
              value={body[FIELDS.CATEGORY_KEY]}
              showSearch
              style={{ width: 200 }}
              placeholder="Select a category"
              optionFilterProp="children"
              onChange={(val) => onChangeFilter(val, FIELDS.CATEGORY_KEY)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              <Option value={''}>All</Option>
              {articleCategories.length > 0 &&
                articleCategories.map((category) => (
                  <Option value={category.key} key={category.id}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col xs={6}>
            <label className="label">Search by</label>
            <Search
              value={body[FIELDS.QUERY]}
              placeholder="Title, name, email, keyword"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
          <Col xs={10}>
            <label className="label">Date Period</label>
            <RangePicker onChange={onChangeDate} />
          </Col>
          <Col xs={2}>
            <label className="label">&nbsp;</label>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={() => handleSubmit()}>
              Apply
            </Button>
          </Col>
        </Row>

        {loadingPage ? (
          <PageSpinner />
        ) : (
          <Row gutter={[0, 16]}>
            {status === 'waiting_approval' && (
              <Col xs={19}>
                <Checkbox onChange={onCheckedAll}>
                  Select All ({selected.length})
                </Checkbox>
              </Col>
            )}

            {status === 'waiting_approval' && selected.length > 0 && (
              <Col xs={5}>
                <Button
                  type="primary"
                  style={{
                    backgroundColor: '#27AE60',
                    border: 'solid 1px #27AE60',
                  }}
                  onClick={() => onOpenBulkUpdateDialog()}>
                  Approve Comment ({selected.length})
                </Button>
              </Col>
            )}

            {comments.map((comment, index) => (
              <Col xs={24} key={index}>
                <CommentSummary
                  checkedActive
                  isHover={comment.status === 'WAITING_APPROVAL'}
                  checked={selected.includes(comment.id)}
                  comment={comment}
                  showAdmin
                  onChangeStatus={onChangeStatus}
                  onChecked={onChecked}
                  reason={comment.status === 'REJECTED' && comment.reason}
                  url={`${process.env.REACT_APP_DOMAIN_URL}/articles/${comment.article_category_key}/${comment.article_slug}`}
                />
              </Col>
            ))}
          </Row>
        )}

        {comments.length > 0 && (
          <div className="pagination-container">
            <Pagination
              pageSize={body.size}
              current={body.page}
              onChange={onChangePage}
              total={body.total_data}
            />
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default withRouter(Comments);
