import api from './index';

export default {
  get() {
    return api.get('quiz/result-page');
  },
  createQuizResultPage(payload) {
    return api.post('quiz/result-page', { request: payload });
  },
  update(payload) {
    return api.put('quiz/result-page/1', { request: payload });
  },
};
