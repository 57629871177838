import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import FormServices from 'api/forms';

import { alertErr } from 'utils';
import { useDebounce } from 'hooks/useDebounce';

import {
  Button,
  Col,
  Icon,
  Layout,
  Pagination,
  Table,
  Typography,
  Row,
  message,
  Popconfirm,
  Input,
} from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const SIZE = 10;

const SectionForm = () => {
  const [forms, setForms] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);

  const debounced = useDebounce(keyword);

  const queries = {
    q: debounced,
    page,
    size: SIZE,
  };

  const getAllForms = async () => {
    try {
      const response = await FormServices.getForms(queries);

      setForms(response.data);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleDuplicate = async (purpose, id) => {
    try {
      await FormServices.duplicateForm(id);

      message.success(`Berhasil duplicate form ${purpose}`);

      getAllForms();
    } catch (err) {
      alertErr(err);
    }
  };

  const handleDelete = async (purpose, id) => {
    try {
      await FormServices.deleteForm(id);

      message.success(`Berhasil menghapus form ${purpose}`);

      getAllForms();
    } catch (err) {
      alertErr(err);
    }
  };

  const handleKeyword = (e) => setKeyword(e.target.value);

  const handlePagination = (e) => {
    setPage(e);
  };

  const columns = [
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <>{record.active ? 'Active' : 'Not Active'}</>,
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      render: (_, record) => <>{record.author.name}</>,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => {
        const publish_date = moment(record.created_at).format('DD MMMM YYYY');
        return publish_date;
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, record) => {
        const updated_date = moment(record.updated_at).format('DD MMMM YYYY');
        return updated_date;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/forms/${record.id}/questions`}>
              <Icon type="question-circle" />
            </Link>
          </div>

          <div className="edit" style={{ marginLeft: 20 }}>
            <Link to={`/cms/forms/${record.id}/history`}>
              <Icon type="history" />
            </Link>
          </div>

          <div
            className="edit"
            style={{ marginLeft: 20 }}
            onClick={() => handleDuplicate(record.purpose, record.id)}>
            <Icon type="diff" />
          </div>

          <div className="edit" style={{ marginLeft: 20 }}>
            <Link to={`/cms/forms/${record.id}/results`}>
              <Icon type="database" />
            </Link>
          </div>

          <div className="edit" style={{ marginLeft: 20 }}>
            <Link to={`/cms/forms/edit/${record.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <Popconfirm
              title="Are you sure？"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => handleDelete(record.purpose, record.id)}>
              <Icon type="delete" />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAllForms();

    return () => setForms([]);
  }, [page, debounced]);

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>Forms</Title>
      </Header>

      <Content>
        <Row align="middle">
          <Col lg={12} xs={24}>
            <Link to="/cms/forms/create">
              <Button type="primary">Create New Form</Button>
            </Link>
          </Col>
          <Col lg={12} xs={24}>
            <Input placeholder="Search Form" onChange={handleKeyword} />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={forms.data}
              columns={columns}
              pagination={false}
              rowKey="id"
              loading={loadingPage}
            />

            {forms.data && forms.data.length > 0 ? (
              <div className="pagination">
                <Pagination
                  current={forms.current_page}
                  onChange={handlePagination}
                  pageSize={SIZE}
                  total={forms.total_data}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default SectionForm;
