import api from './index';

export default {
  get() {
    return api.get(`/rata-edu`);
  },

  update(rata_edus) {
    return api.put(`/rata-edu`, { request: rata_edus });
  },
};
