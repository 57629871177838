import api from './index';

export default {
  // form
  getForms({ q, active, page, size }) {
    let url = '/forms';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    active && filters.push(`active=${active}`);
    page !== '' && filters.push(`page=${page}`);
    size !== '' && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getFormById(id) {
    return api.get(`/forms/${id}`);
  },
  createForm(payload) {
    return api.post('/forms', { request: payload });
  },
  updateForm(id, payload) {
    return api.put(`/forms/${id}`, { request: payload });
  },
  duplicateForm(id) {
    return api.patch(`/forms/${id}`);
  },
  deleteForm(id) {
    return api.delete(`/forms/${id}`);
  },

  // questions
  getQuestions({ formId, q, active, page, size }) {
    let url = `/forms/${formId}/questions`;
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    active && filters.push(`active=${active}`);
    page !== '' && filters.push(`page=${page}`);
    size !== '' && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  getQuestionById(formId, questionId) {
    return api.get(`/forms/${formId}/questions/${questionId}`);
  },
  createFormQuestion(formId, payload) {
    return api.post(`/forms/${formId}/questions`, { request: payload });
  },
  updateFormQuestion(formId, questionId, payload) {
    return api.put(`/forms/${formId}/questions/${questionId}`, {
      request: payload,
    });
  },
  deleteQuestion(formId, questionId) {
    return api.delete(`/forms/${formId}/questions/${questionId}`);
  },

  // results users
  getResultUser({ formId, q, page, size }) {
    let url = `/forms/${formId}/users`;
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page !== '' && filters.push(`page=${page}`);
    size !== '' && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  deleteResultUser(formId, userId) {
    return api.delete(`/forms/${formId}/users/${userId}`);
  },

  // answer users
  getAnswerUser({ formId, resultId }) {
    return api.get(`/forms/${formId}/results/${resultId}`);
  },
};
