import React from 'react';
import moment from 'moment';

import { Avatar, Button, Col, Icon, Row, Typography } from 'antd';

import {
  capitalize,
  generateColorHsl,
  getInitials,
  getRange,
  textReplacement,
} from 'utils';

const HistoryItem = (props) => {
  const { histories, onLoadMore, pagination, onOpenDetailHistory } = props;

  const saturationRange = getRange(50, 10);
  const lightnessRange = getRange(50, 10);

  return (
    <>
      {histories.length > 0 ? (
        histories.map((history) => {
          const username = history.creator.name;
          const initialsName = getInitials(username);
          const bgColor = generateColorHsl(
            username,
            saturationRange,
            lightnessRange
          );

          return (
            <Row type="flex" style={{ flexWrap: 'nowrap', marginBottom: 30 }}>
              <Col>
                <Avatar
                  size={60}
                  style={{
                    marginRight: 30,
                    backgroundColor: bgColor,
                    verticalAlign: 'middle',
                    fontWeight: 500,
                  }}>
                  {initialsName}
                </Avatar>
              </Col>
              <div style={{ width: '100%' }}>
                <Row
                  style={{
                    width: '100%',
                  }}
                  type="flex">
                  <Col xs={12} style={{ marginBottom: 10 }}>
                    <Typography.Title
                      level={4}
                      style={{ marginBottom: 0 }}
                      ellipsis>
                      {username}
                    </Typography.Title>
                  </Col>
                  <Col xs={12} style={{ textAlign: 'end' }}>
                    <Typography.Text>
                      {moment(history.created_at).format(
                        'DD MMMM YYYY - HH:mm:ss'
                      )}
                    </Typography.Text>
                  </Col>
                </Row>

                {['CREATED', 'DELETED'].includes(history.menu_column) ? (
                  <Typography.Text>
                    {textReplacement(history.menu_column)} the{' '}
                    <Typography.Text strong>
                      {capitalize(history.menu_type)}
                    </Typography.Text>
                    {history.menu_column_extra ? (
                      <>
                        &nbsp;-&nbsp;
                        <Typography.Text strong>
                          {capitalize(history.menu_column_extra)}
                        </Typography.Text>
                      </>
                    ) : null}
                  </Typography.Text>
                ) : (
                  <>
                    <Typography.Text>
                      Change the{' '}
                      {history.menu_column_extra ? (
                        <>
                          <Typography.Text strong>
                            {capitalize(history.menu_column_extra)}
                          </Typography.Text>
                          &nbsp;-&nbsp;
                          <Typography.Text strong>
                            {capitalize(history.menu_column)}
                          </Typography.Text>
                        </>
                      ) : (
                        <Typography.Text strong>
                          {capitalize(history.menu_column)}
                        </Typography.Text>
                      )}
                    </Typography.Text>
                    <Row type="flex" align="middle">
                      <Col xs={12}>
                        <Typography.Text>
                          <span style={{ color: '#c9c9c9' }}>
                            {history.old_value || '-'}
                          </span>
                        </Typography.Text>
                        <Icon type="arrow-right" style={{ margin: '0 8px' }} />
                        <Typography.Text>
                          {history.new_value || '-'}
                        </Typography.Text>
                      </Col>
                      {[
                        'TYPE_IMAGE',
                        'TYPE_COLOR',
                        'TYPE_LINK',
                        'TYPE_CONTENT',
                      ].includes(history.history_type) ? (
                        <Col xs={12} style={{ textAlign: 'end' }}>
                          <Icon
                            type="select"
                            style={{
                              transform: 'rotate(90deg)',
                              cursor: 'pointer',
                            }}
                            onClick={() => onOpenDetailHistory(history.id)}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </>
                )}
              </div>
            </Row>
          );
        })
      ) : (
        <h3 style={{ textAlign: 'center' }}>
          Oops, data history belum tersedia
        </h3>
      )}
      {histories.length > 0 &&
        pagination.currentPage !== pagination.totalPage && (
          <Button block style={{ marginTop: 20 }} onClick={onLoadMore}>
            Load More
          </Button>
        )}
    </>
  );
};

export default HistoryItem;
