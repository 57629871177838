import { Button, Form, Input, Layout, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';

import TestimoniesApi from 'api/v4/testimony';

const TestimoniesSection = ({ form }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [content, setContent] = useState({});

  const fetchTestimoniesContent = async () => {
    const { data } = await TestimoniesApi.getPageContent();
    const content = data.data;

    setContent(content);
  };

  const submit = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      const payload = { ...values };

      if (!err) {
        try {
          setLoadingButton(true);

          await TestimoniesApi.updatePageContent(payload);
          fetchTestimoniesContent();

          message.success('Berhasil mengubah data');
        } catch (err) {
          message.error('Tidak dapat menghubungi server, cek koneksi');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  const { getFieldDecorator } = form;

  useEffect(() => {
    fetchTestimoniesContent();
  }, []);

  return (
    <Layout className="rata-cms-clinic-create-edit">
      <Layout.Header>
        <Typography.Title>Section Testimonies</Typography.Title>
      </Layout.Header>

      <Layout.Content>
        <Form
          onSubmit={(event) => submit(event)}
          className="pd-admin-create-form">
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Please input the title!',
                },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Title should not start or end characters with spaces',
                },
              ],
              initialValue: content?.title,
            })(<Input placeholder="Insert title" />)}
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: '100%' }}
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export const TestimoniesSectionForm = Form.create({
  name: 'testimonies_section_form',
})(TestimoniesSection);

export default TestimoniesSection;
