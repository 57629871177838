import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Col,
  Row,
  Layout,
  Table,
  Typography,
  Input,
  Pagination,
  Icon,
} from 'antd';

import CampaignApi from 'api/campaigns';
import { alertErr } from 'utils';
import moment from 'moment';

const { Header, Content } = Layout;
const { Title } = Typography;

const CampaignRecords = () => {
  const { promo_code } = useParams();

  const COLUMN_CAMPAIGN_RECORDS = [
    {
      title: 'Date Time',
      dataIndex: 'epoch_created_at',
      key: 'epoch_created_at',
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Promo Code',
      dataIndex: 'promo_code',
      key: 'promo_code',
      render: (v) => v || '-',
    },
    {
      title: 'Customer Phone',
      dataIndex: 'customer_phone',
      key: 'customer_phone',
      render: (v) => v || '-',
    },
    {
      title: 'Inbound Message',
      dataIndex: 'inbound_message',
      key: 'inbound_message',
      render: (v) => v || '-',
      width: 450,
    },
    {
      title: 'Response Meta ID',
      dataIndex: 'response_meta_id',
      key: 'response_meta_id',
      render: (v) => v || '-',
    },
  ];

  const [state, setState] = useState({
    keyword: '',
    search: '',
    current_page: 1,
    total_page: 0,
    total_data: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [recordsCampaign, setRecordsCampaign] = useState([]);

  const getCampaigns = async (q, page = 1) => {
    try {
      setIsLoading(true);

      const { data } = await CampaignApi.campaignRecordsByPromoCode(
        promo_code,
        q,
        page,
        10
      );

      setState((prev) => ({
        ...prev,
        current_page: data.current_page,
        total_page: data.total_page,
        total_data: data.total_data,
      }));

      const CAMPAIGN_ADS = data.data;

      setRecordsCampaign(CAMPAIGN_ADS);
    } catch (err) {
      alertErr(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = (value) => getCampaigns(state.search, value);

  useEffect(() => {
    getCampaigns(state.search, state.current_page);
  }, [state.search, state.current_page]);

  return (
    <Layout className="rata-cms-users-campaign pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/campaign-ads">
            <Icon type="caret-left" />
          </Link>
          Records Campaign Ads
        </Title>
      </Header>

      <Content>
        <Row
          style={{ marginBottom: 24 }}
          type="flex"
          align="middle"
          justify="space-between">
          <Input.Search
            placeholder="Search by keyword"
            allowClear
            onChange={(e) => {
              e.persist();
              if (e.target.value.length > 0) {
                setState((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }));
              } else {
                setState((prev) => ({ ...prev, keyword: '', search: '' }));
              }
            }}
            onPressEnter={() =>
              setState((prev) => ({
                ...prev,
                search: state.keyword,
                current_page: 1,
              }))
            }
            value={state.keyword}
            style={{ width: 300 }}
          />
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={recordsCampaign}
              columns={COLUMN_CAMPAIGN_RECORDS}
              loading={isLoading}
              pagination={false}
              rowKey="id"
            />

            {recordsCampaign.length > 0 && (
              <div style={{ margin: '30px auto 0', textAlign: 'center' }}>
                <Pagination
                  current={state.current_page}
                  onChange={handlePagination}
                  total={state.total_data}
                />
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CampaignRecords;
