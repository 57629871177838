export const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ],
};

export const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const FIELDS = {
  TITLE: 'title',
  SLUG_IS_MANUAL: 'slug_is_manual',
  SLUG: 'slug',
  URL_LINK: 'url_link',
  URL_LINK_ALT: 'url_link_alt',
  URL_LINK_MOBILE: 'url_link_mobile',
  URL_LINK_MOBILE_ALT: 'url_link_mobile_alt',
  BUTTON_TEXT: 'button_text',
  BUTTON_URL: 'button_url',
  BUTTON_IS_ACTIVE: 'button_is_active',
  MAP_IS_ACTIVE: 'map_is_active',
  MAP_TITLE: 'map_title',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  IS_FORM_ACTIVE: 'is_form_active',
  FORM_MEDIA_URL: 'form_media_url',
  FORM_MEDIA_URL_ALT: 'form_media_url_alt',
  FORM_HEADLINE: 'form_headline',
  FORM_DESCRIPTION: 'form_description',
  FORM_BUTTON_URL: 'form_button_url',
  FORM_BUTTON_TEXT: 'form_button_text',
  FORM_SHOW_SUCCESS_MESSAGE: 'form_show_success_message',
  DESKTOP_MAP_MEDIA_URL: 'desktop_map_media_url',
  DESKTOP_MAP_MEDIA_URL_ALT: 'desktop_map_media_url_alt',
  MOBILE_MAP_MEDIA_URL: 'mobile_map_media_url',
  MOBILE_MAP_MEDIA_URL_ALT: 'mobile_map_media_url_alt',
  WHATSAPP_IS_ACTIVE: 'is_whatsapp_button_active',
  WHATSAPP_TEXT: 'whatsapp_text',
  WHATSAPP_URL: 'whatsapp_url',
  SECTION_NUMBER: 'section_number',
  SECTION_TITLE: 'section_title',
  SECTION_TAG: 'section_tag',
  SECTION_SUB_TITLE: 'section_sub_title',
  SECTION_SELECTED: 'section_selected',
  SECTION_BUTTON_TEXT: 'section_button_text',
  SECTION_BUTTON_HEIGHT: 'section_button_height',
  SECTION_BUTTON_WIDTH: 'section_button_width',
  SECTION_BUTTON_IS_ACTIVE: 'section_button_is_active',
  SECTION_BUTTON_URL: 'section_button_url',
  SECTION_BUTTON_COLOR: 'section_button_color',
  SECTION_BUTTON_FONT_COLOR: 'section_button_font_color',
  SECTION_VIDEO_URL: 'section_video_url',
  SECTION_VIDEO_HEADLINE: 'section_video_headline',
  SECTION_VIDEO_DESCRIPTION: 'section_video_description',
  BANNER_CLICK: 'banner_click',
  BANNER_CLICK_URL: 'banner_click_url',
  HEADER_URL: 'header_url',
  TEMPORARY_WHATSAPP_NUMBER: 'temporary_whatsapp_number',
  TEMPORARY_WHATSAPP_MESSAGE: 'temporary_whatsapp_message',
  IS_SHOW_FLOATING_BANNER: 'is_show_floating_banner',
  SECTION_FORM_SLUG: 'section_form_slug',
  SECTION_FORM_HEADLINE: 'section_form_headline',
  SECTION_FORM_DESCRIPTION: 'section_form_description',
  SECTION_FORM_IMAGE_URL: 'section_form_image_url',
  SECTION_FORM_IMAGE_URL_ALT: 'section_form_image_url_alt',
  SECTION_FORM_SHOW_MESSAGE: 'section_form_show_message',
  // IS_SHOW_NEWSLETTER: 'is_show_newsletter',
};
