import api from './index';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_API_URL}/v1/cms/uploads/medias`;

export default {
  getUploadURL(file_purpose, file_media) {
    return api.post(`/uploads/medias`, {
      file_purpose,
      file_media,
    });
  },
  deleteImage(url) {
    return api.delete(`/objects?object_url=${url}`);
  },
  uploadImage(payload) {
    const formData = new FormData();

    if (payload.promotion_id !== null) {
      formData.append('promotion_id', payload.promotion_id);
    }
    formData.append('file_purpose', payload.file_purpose);
    formData.append('file_media', payload.file_media);

    return axios.post(apiUrl, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('cms_token') || ''}`,
      },
    });
  },
};
