export const FIELDS = {
  TITLE: 'title',
  SLUG: 'slug',
  URL: 'url',
  ALT_TEXT: 'alt_text',
  IS_ACTIVE: 'is_active',
  IS_POPULAR: 'is_popular',
  IS_PRIORITY: 'is_priority',
  CONTENT: 'content',
  SHORT_CONTENT: 'short_content',
  MEDIA_URL: 'media_url',
  MEDIA_URL_ALT: 'media_url_alt',
  SOURCE_IMAGE: 'source_image',
  ARTICLE_CATEGORY: 'article_category',
  BUTTON_DESKTOP_MEDIA_URL: 'desktop_banner_media_url',
  DESKTOP_MEDIA_URL_ALT: 'desktop_banner_media_url_alt',
  MOBILE_MEDIA_URL: 'mobil_banner_media_url',
  MOBILE_MEDIA_URL_ALT: 'mobil_banner_media_url_alt',
  BUTTON_IS_ACTIVE: 'button_is_active',
  BUTTON_TEXT: 'button_text',
  BUTTON_LINK_URL: 'button_link_url',
  BUTTON_BACKGROUND_COLOR: 'button_background_color',
  BUTTON_FONT_COLOR: 'button_font_color',
  BUTTON_WIDTH: 'button_width',
  BUTTON_HEIGHT: 'button_height',
  BUTTON_MARGIN_SIDE: 'button_margin_side',
  BUTTON_POSITION: 'button_position',
  IS_WHATSAPP_BUTTON_ACTIVE: 'is_whatsapp_button_active',
  WHATSAPP_TEXT: 'whatsapp_text',
  WHATSAPP_URL: 'whatsapp_url',
  // TEMPORARY_WHATSAPP_NUMBER: 'temporary_whatsapp_number',
  // TEMPORARY_WHATSAPP_MESSAGE: 'temporary_whatsapp_message',
};

export const CATEGORIES = [
  {
    id: 1,
    key: 'aligner-information',
    name: 'Aligner Information',
  },
  {
    id: 2,
    key: 'aligner-user-guide',
    name: 'Aligner User Guide',
  },
  {
    id: 3,
    key: 'real-stories',
    name: 'Real Stories',
  },
  {
    id: 3,
    key: 'dental-health',
    name: 'Dental Health',
  },
  {
    id: 4,
    key: 'our-community',
    name: 'Our Community',
  },
  {
    id: 5,
    key: 'updates',
    name: 'Updates',
  },
  {
    id: 6,
    key: 'our-stories',
    name: 'Our Stories',
  },
];

export const config = {
  hidePoweredByJodit: true,
  useSearch: false,
  language: 'en',
  toolbarSticky: false,
  showXPathInStatusbar: false,
  enableDragAndDropFileToEditor: true,
  enter: 'p',
  placeholder: 'Type here...',
  buttons: [
    'bold',
    'underline',
    'italic',
    'strikethrough',
    'align',
    'ul',
    'ol',
    'fontsize',
    'brush',
    {
      name: 'paragraph',
      list: {
        p: 'Paragraph',
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
        h4: 'Heading 4',
        h5: 'Heading 5',
        h6: 'Heading 6',
      },
    },
    'image',
    'link',
    'indent',
    'outdent',
    'symbols',
    'undo',
    'redo',
  ],
  uploader: {
    // url: `${process.env.REACT_APP_API_URL}/v1/cms/uploads/medias`,
    // method: 'POST',
    filesVariableName() {
      return `files`;
    },
    withCredentials: false,
    pathVariableName: 'path',
    format: 'json',
    insertImageAsBase64URI: true,
    maxFileSize: 2 * 1024 * 1024, // 5MB
    imagesExtensions: ['jpg', 'png', 'jpeg', 'webp'],
    // headers: {
    //   Authorization: `Bearer ${localStorage.getItem('cms_token')}`,
    // },
    isSuccess: function (resp) {
      return !resp.error;
    },
    getMessage: function (resp) {
      return resp.msg;
    },
    process: function (resp) {
      return {
        files: resp.filename || [],
        baseurl: resp.url,
      };
    },
    defaultHandlerSuccess: function (data) {
      const field = 'files';
      if (data[field] && data[field].length) {
        this.s.insertImage(data[field][0]);
      }
    },
    error: function (e) {
      // this?.message?.message(e.getMessage(), 'error', 4000);
    },
  },
};
