import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Col,
  Row,
  Layout,
  Table,
  Typography,
  Input,
  Pagination,
  Icon,
  Button,
  Select,
  DatePicker,
} from 'antd';

import CampaignApi from 'api/campaigns';
import { alertErr } from 'utils';
import moment from 'moment';
import { DATE_PERIODE_OPTIONS } from 'constants/index';

const { Header, Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

const Campaigns = () => {
  const COLUMN_CAMPAIGNS = [
    {
      title: 'Ads URL',
      dataIndex: 'promo_ad_url',
      key: 'promo_ad_url',
      render: (v) => (
        <a
          href={v}
          rel="noopener noreferrer"
          target="_blank"
          style={{ color: 'revert' }}>
          {v}
        </a>
      ),
    },
    {
      title: 'Promo Code',
      dataIndex: 'promo_code',
      key: 'promo_code',
    },
    {
      title: 'New Chat',
      dataIndex: 'total_new_chat',
      key: 'total_new_chat',
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <div style={{ fontSize: 20 }}>
              <Link to={`/cms/campaign-ads/edit/${record.id}`}>
                <Icon type="edit" />
              </Link>
            </div>
            <div style={{ fontSize: 20, transform: 'rotate(0deg)' }}>
              <Link to={`/cms/campaign-ads/${record.promo_code}/records`}>
                <Icon type="tag" />
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  const [state, setState] = useState({
    keyword: '',
    search: '',
    current_page: 1,
    total_page: 0,
    total_data: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [campaignAds, setCampaignAds] = useState([]);
  const [periode, setPeriode] = useState(undefined);
  const [datePeriode, setDatePeriode] = useState({
    start_date: '',
    end_date: '',
  });

  const handleChangePeriode = (val) => {
    setPeriode(val);

    let start_date, end_date;

    switch (val) {
      case 'Today':
        start_date = moment().format('YYYY-MM-DD 00:00:00');
        end_date = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case 'This Week':
        start_date = moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00');
        end_date = moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59');
        break;
      case 'Last 7 Days':
        start_date = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00');
        end_date = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case 'Last 30 Days':
        start_date = moment()
          .subtract(29, 'days')
          .format('YYYY-MM-DD 00:00:00');
        end_date = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case 'Last 3 Months':
        start_date = moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD 00:00:00');
        end_date = moment().format('YYYY-MM-DD 23:59:59');
        break;
      case 'This Month':
        start_date = moment().startOf('month').format('YYYY-MM-DD 00:00:00');
        end_date = moment().endOf('month').format('YYYY-MM-DD 23:59:59');
        break;
      case 'This Year':
        start_date = moment().startOf('year').format('YYYY-MM-DD 00:00:00');
        end_date = moment().endOf('year').format('YYYY-MM-DD 23:59:59');
        break;
      default:
        break;
    }

    setDatePeriode({
      ...datePeriode,
      start_date,
      end_date,
    });
  };

  const getCampaigns = async (q, page = 1, start_date, end_date) => {
    try {
      setIsLoading(true);

      const { data } = await CampaignApi.listCampaignAds(
        q,
        page,
        10,
        start_date,
        end_date
      );

      setState((prev) => ({
        ...prev,
        current_page: data.current_page,
        total_page: data.total_page,
        total_data: data.total_data,
      }));

      const CAMPAIGN_ADS = data.data;

      setCampaignAds(CAMPAIGN_ADS);
    } catch (err) {
      alertErr(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = (value) =>
    getCampaigns(
      state.search,
      value,
      datePeriode.start_date,
      datePeriode.end_date
    );

  useEffect(() => {
    getCampaigns(
      state.search,
      state.current_page,
      datePeriode.start_date,
      datePeriode.end_date
    );
  }, [
    state.search,
    state.current_page,
    datePeriode.start_date,
    datePeriode.end_date,
  ]);

  return (
    <Layout className="rata-cms-users-campaign pd-cms-form-1">
      <Header>
        <Title> Campaign Ads</Title>
      </Header>

      <Content>
        <Link to="/cms/campaign-ads/create">
          <Button type="primary">Create New Campaign</Button>
        </Link>

        <Row
          style={{ margin: '30px auto' }}
          type="flex"
          align="middle"
          gutter={[12, 0]}>
          <Col xs={6}>
            <Input.Search
              placeholder="Search by keyword"
              allowClear
              onChange={(e) => {
                e.persist();
                if (e.target.value.length > 0) {
                  setState((prev) => ({
                    ...prev,
                    keyword: e.target.value,
                  }));
                } else {
                  setState((prev) => ({ ...prev, keyword: '', search: '' }));
                }
              }}
              onPressEnter={() =>
                setState((prev) => ({
                  ...prev,
                  search: state.keyword,
                  current_page: 1,
                }))
              }
              value={state.keyword}
            />
          </Col>
          <Col xs={6}>
            <Select
              onChange={(v) => handleChangePeriode(v)}
              style={{ width: '100%' }}
              placeholder="Select Period"
              allowClear>
              {DATE_PERIODE_OPTIONS?.map((periode, idx) => (
                <Option value={periode.value} key={idx}>
                  {periode.label}
                </Option>
              ))}
            </Select>
          </Col>
          {periode === 'Custom Range' && (
            <Col xs={6}>
              <DatePicker.RangePicker
                format="YYYY-MM-DD"
                value={[
                  datePeriode.start_date
                    ? moment(datePeriode.start_date, 'YYYY-MM-DD 00:00:00')
                    : '',
                  datePeriode.end_date
                    ? moment(datePeriode.end_date, 'YYYY-MM-DD 23:59:59')
                    : '',
                ]}
                onChange={(_, dateString) => {
                  const [start_date, end_date] = dateString;

                  setDatePeriode({
                    ...datePeriode,
                    start_date: start_date
                      ? moment(start_date).format('YYYY-MM-DD 00:00:00')
                      : '',
                    end_date: end_date
                      ? moment(end_date).format('YYYY-MM-DD 23:59:59')
                      : '',
                  });
                }}
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={campaignAds}
              columns={COLUMN_CAMPAIGNS}
              loading={isLoading}
              pagination={false}
              rowKey="id"
            />

            {campaignAds.length > 0 && (
              <div style={{ margin: '30px auto 0', textAlign: 'center' }}>
                <Pagination
                  current={state.current_page}
                  onChange={handlePagination}
                  total={state.total_data || 1}
                />
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Campaigns;
