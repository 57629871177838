import React, { useEffect, useState, useRef, useCallback } from 'react';
import LoadingContent from 'components/loading-skeleton';
import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  // Form,
  // Input,
} from 'antd';

import KolsApi from 'api/kols';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export const Kols = () => {
  const [kols, setKols] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [page, setPage] = useState(1);
  let observer = useRef();
  const [more, setMore] = useState(false);

  async function deletingKols(id) {
    try {
      await KolsApi.deleteKols(id);
      message.success('Item has been deleted');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletingKols(id);
      },
      onCancel() {},
    });
  }

  async function getKols() {
    setLoadingPage(true);
    try {
      const { data } = await KolsApi.listKols('', page, 10);
      const kolsList = data.data;

      setMore(Boolean(data.next_page));
      setKols((prev) => [...new Set([...prev, ...kolsList])]);
    } catch (error) {
      message.error(error, 'error');
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getKols();
  }, [page]);

  const lastElementRef = useCallback(
    (element) => {
      if (observer.current) observer.current.disconnect();
      if (!more) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && more) {
          setLoadingPage(true);
          setTimeout(() => {
            setPage((prev) => prev + 1);
            setLoadingPage(false);
          }, 800);
        }
      });
      if (element) observer.current.observe(element);
    },
    [more]
  );

  const SortableItem = (kol) => {
    return (
      <div ref={lastElementRef}>
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          className="kol-item draggable-item"
          gutter={[32, 32]}>
          {/* image */}
          <Col xs={8}>
            <div style={{ width: 150, height: '100%' }}>
              <img
                src={kol.media_url}
                alt={kol.media_url_alt}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            </div>
          </Col>

          {/* message and name */}
          <Col xs={8}>
            <div className="container--center">
              <div className="title">{kol.name}</div>
              <div className="subtitle">{kol.username}</div>
            </div>
          </Col>

          {/* button group */}
          <Col xs={8}>
            <Row type="flex" justify="end">
              <Button type="danger" onClick={handleDelete.bind(this, kol.id)}>
                Delete
              </Button>
              <Button style={{ marginLeft: 24 }} type="primary">
                <Link to={`/cms/kols/edit/${kol.id}`}>Edit</Link>
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...kols];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);
    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });
    try {
      setKols(list);
      await KolsApi.updateKolsOrder(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  return (
    <Layout className="pd-cms-home-testimonials pd-cms-form-1">
      <Header>
        <Title>KOLs</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/kols/create'}>Add New KOL</Link>
        </Button>

        <PdSortable
          render={SortableItem}
          items={kols}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
        {loadingPage && <LoadingContent />}
      </Content>
    </Layout>
  );
};
