import React, { useState } from 'react';

import { Button, Input, Layout, Typography, Icon, message, Form } from 'antd';

import './style.scss';
import { phoneRegExp } from 'utils';
import { useEffect } from 'react';

const { Header, Content } = Layout;
const { Title } = Typography;

const Generator = ({ form }) => {
  const [urlResult, setUrlResult] = useState('');

  const { getFieldDecorator } = form;

  const generateLink = (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        const number = values.phone_number;
        const message = values.message.replaceAll(' ', '%20');
        const url = 'https://api.whatsapp.com';
        const end_url = `${url}/send?phone=62${number}&text=${message}`;
        setUrlResult(end_url);
      }
    });
  };

  const copyToClipboard = () => {
    const cb = navigator.clipboard;
    const paragraph = urlResult;

    cb.writeText(paragraph).then(() => {
      message.success('Pesan telah berhasil disalin ke clipboard');
    });
  };

  useEffect(() => {
    urlResult && form.resetFields();
  }, [urlResult]);

  return (
    <Layout className="pd-cms-generator">
      <Header>
        <Title id="title-url-wa-generator">URL Whatsapp Generator</Title>
      </Header>

      <Content>
        <Form.Item label="Phone Number" id="phone-number-key">
          {getFieldDecorator('phone_number', {
            rules: [
              {
                required: true,
                message: 'Please input the phone number!',
              },
              {
                pattern: phoneRegExp,
                message: 'Must be a valid phone number. (13digits)',
              },
            ],
            initialValue: '',
          })(
            <Input
              addonBefore="+62"
              placeholder="input the phone number"
              id="phone-number-input"
            />
          )}
        </Form.Item>

        <Form.Item label="Message" id="message-text-key">
          {getFieldDecorator('message', {
            rules: [
              {
                required: true,
                message: 'Please input the message',
              },
            ],
            initialValue: '',
          })(<Input placeholder="input the message" id="message-text-input" />)}
        </Form.Item>

        <Button type="primary" onClick={generateLink} id="bt-url-wa-generate">
          Generate
        </Button>

        {urlResult !== '' && (
          <div className="url-result">
            <h4 id="title-url-wa-result">Result</h4>
            <p id="text-url-wa-result">{urlResult}</p>

            <div
              className="copy-btn"
              onClick={copyToClipboard}
              id="bt-url-wa-copy">
              <Icon type="copy" /> Copy
            </div>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export const GenerateLinkForm = Form.create({
  name: 'generate_link_whatsapp',
})(Generator);

export default GenerateLinkForm;
