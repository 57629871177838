import React, { createContext, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import CommentApi from 'api/comment';

import { AuthenticationContext } from 'contexts/authentication';

export const CommentContext = createContext();

function CommentContextProvider({ children }) {
  const [counters, setCounters] = useState(0);

  const { authenticated } = useContext(AuthenticationContext || {});

  async function getArticleCounters() {
    const { data } = await CommentApi.getCounters(
      'module=ARTICLE&status=WAITING_APPROVAL'
    );
    const { counter_total } = data;

    setCounters(counter_total);
  }

  useEffect(() => {
    authenticated && getArticleCounters();

    return () => setCounters(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  return (
    <CommentContext.Provider
      value={{
        counters,
        getArticleCounters,
      }}>
      {children}
    </CommentContext.Provider>
  );
}

CommentContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CommentContextProvider;
