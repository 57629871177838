import React, { useEffect, useState, useRef, useCallback } from 'react';
import LoadingContent from 'components/loading-skeleton';
import { Link } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  message,
  Modal,
  Form,
  // Input,
} from 'antd';

import TestimonyApi from 'api/v3/testimony';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export const Testimonials = Form.create({ name: 'testimonies' })(({ form }) => {
  const [testimonies, setTestimonies] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [page, setPage] = useState(1);
  let observer = useRef();
  const [more, setMore] = useState(false);

  // const [submitLoading, setSubmitLoading] = useState(false);

  async function deletingTestimony(id) {
    try {
      await TestimonyApi.deleteTestimonyDetail(id);
      await getTesimonyList();
      message.success('Item has been deleted');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deletingTestimony(id);
      },
      onCancel() {},
    });
  }

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   form.validateFields(async (err, values) => {
  //     if (!err) {
  //       try {
  //         setSubmitLoading(true);
  //         await homeApi.updateHomePage(values);
  //         message.success('Data has been updated');
  //       } catch (err) {
  //         const errMessage = _.get(err, 'response.data.message');
  //         message.error(errMessage || 'Cannot connect to the server');
  //       } finally {
  //         setSubmitLoading(false);
  //       }
  //     }
  //   });
  // }

  async function getTesimonyList() {
    setLoadingPage(true);

    const { data } = await TestimonyApi.listTestimonies(page, 5);
    setLoadingPage(false);
    const testimonyList = data.data;
    setMore(Boolean(data.next_page));
    setTestimonies((prev) => [...new Set([...prev, ...testimonyList])]);
  }

  useEffect(() => {
    getTesimonyList();
  }, [page]);

  const lastElementRef = useCallback(
    (element) => {
      if (observer.current) observer.current.disconnect();
      if (!more) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && more) {
          setLoadingPage(true);
          setTimeout(() => {
            setPage((prev) => prev + 1);
            setLoadingPage(false);
          }, 800);
        }
      });
      if (element) observer.current.observe(element);
    },
    [more]
  );

  const SortableItem = (testimony) => {
    return (
      <div ref={lastElementRef}>
        <Row
          type="flex"
          align="middle"
          justify="space-around"
          className="testimony-item draggable-item"
          gutter={32}>
          {/* image */}
          <div className="profile-image">
            <img src={testimony.media_url} alt={testimony.name} />
          </div>

          {/* message and name */}
          <Col span={10}>
            <div className="container--center">
              <div className="title">{testimony.name}</div>
              <div className="description">{testimony.message}</div>
            </div>
          </Col>

          {/* button group */}
          <Col>
            <Row type="flex" justify="end">
              <Button
                type="danger"
                onClick={handleDelete.bind(this, testimony.id)}>
                Delete
              </Button>
              <Button style={{ marginLeft: 24 }} type="primary">
                <Link to={`/cms/home/testimonials/edit/${testimony.id}`}>
                  Edit
                </Link>
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...testimonies];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);
    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });
    try {
      setTestimonies(list);
      await TestimonyApi.updateTestimonyOrder(payload);
      message.success('Ordering has been updated');
    } catch {
      message.error('Cannot connect to the server');
    }
  }

  return (
    <Layout className="pd-cms-home-testimonials pd-cms-form-1">
      <Header>
        <Title>Before After</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/home/testimonials/create'}>Add New Before After</Link>
        </Button>

        <PdSortable
          render={SortableItem}
          items={testimonies}
          onSortEnd={onSortEnd}
          lockAxis="y"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
        {loadingPage && <LoadingContent />}
      </Content>
    </Layout>
  );
});
