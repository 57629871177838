/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
  Input,
} from 'antd';

import ReportApi from 'api/report';
import QuizAnswersApi from 'api/v3/quiz_answer';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

const QuizAnswers = () => {
  const [answers, setAnswers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Telp',
      dataIndex: 'user_phone',
      key: 'user_phone',
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Bentuk Gigi',
      dataIndex: 'user_teeth_type',
      render: (teeth_type) => <div>{teeth_type}</div>,
    },
    {
      title: 'Senyum Gigi',
      dataIndex: 'is_teeth_when_smile',
      key: 'is_teeth_when_smile',
      render: (is_teeth_when_smile) => (
        <div>{is_teeth_when_smile ? 'Tentu Saja' : 'Malu'}</div>
      ),
    },
    {
      title: 'Makanan Nyangkut',
      dataIndex: 'is_food_stuck',
      key: 'is_food_stuck',
      render: (is_food_stuck) => (
        <div>{is_food_stuck ? 'Iya dong' : 'Gak dong!'}</div>
      ),
    },
    {
      title: 'Gusi Berdarah',
      dataIndex: 'is_bleed',
      key: 'is_bleed',
      render: (is_bleed) => <div>{is_bleed ? 'Kok tau sih' : 'Ga dong!'}</div>,
    },
  ];

  const exportToCSV = async () => {
    const { data } = await ReportApi.newQuiz();

    const link = document.createElement('a');
    link.href = data.data.url;
    link.download = data.data.file_name;
    link.click();

    message.success('Data berhasil diunduh, mohon tunggu');
  };

  const getQuizAnswers = async () => {
    try {
      setIsLoading(true);

      const { data } = await QuizAnswersApi.getNew();

      const answers = data.data;

      setAnswers(answers);
    } catch {
      message.error('Tidak dapat menghubungi server, cek koneksi');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuizAnswers();
  }, []);

  useEffect(() => {
    if (keyword) {
      const filterData = answers.filter((answer) =>
        answer.user_name?.toLowerCase().includes(keyword.toLowerCase())
      );

      setFilterData(filterData);
    } else {
      setFilterData(answers);
    }
  }, [answers, keyword]);

  return (
    <Layout className="rata-cms-quiz-answers pd-cms-form-1">
      <Header>
        <Title>Quiz Report</Title>
      </Header>

      <Content>
        <Row gutter={[24, 12]}>
          <Col xs={12}>
            <div className="label">Search by</div>
            <Search onChange={(e) => setKeyword(e.target.value)} />
          </Col>

          <Col xs={12}>
            <div className="label">Filter by</div>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={filterData}
              columns={columns}
              loading={isLoading}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default QuizAnswers;
