import moment from 'moment';
import api from './index';

export default {
  listCampaignAds(q, page, size, start_date, end_date) {
    let url = '/campaign-ads';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page && filters.push(`page=${page}`);
    size && filters.push(`size=${size}`);
    start_date && filters.push(`start_date=${moment(start_date).valueOf()}`);
    end_date && filters.push(`end_date=${moment(end_date).valueOf()}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  detailCampaignAds(id) {
    return api.get(`/campaign-ads/${id}`);
  },
  campaignRecordsByPromoCode(promoCode, q, page, size) {
    let url = `/campaign-ads/${promoCode}/records`;
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page && filters.push(`page=${page}`);
    size && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  createCampaignAds(payload) {
    return api.post(`/campaign-ads`, { request: payload });
  },
  updateCampaignAdsDetail(id, payload) {
    return api.put(`/campaign-ads/${id}`, { request: payload });
  },
  //   updateCampaignAdsOrder(payload) {
  //     return api.put(`/campaign-ads`, { request: payload });
  //   },
  deleteCampaignAds(id) {
    return api.delete(`/campaign-ads/${id}`);
  },
};
