import React, { useEffect, useState } from 'react';

import MenuPageService from 'api/menuPage';
import NewsLetterSectionsService from 'api/newsletter-sections';

import {
  Button,
  Input,
  Form,
  Layout,
  Typography,
  Row,
  Select,
  message,
} from 'antd';

import { PageSpinner } from 'components/page-spinner';

import { FIELDS } from './constant';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const NewsletterSection = ({ form }) => {
  const [menus, setMenus] = useState([]);
  const [newsLetters, setNewsLetters] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { getFieldDecorator } = form;

  const getMenuPages = async () => {
    try {
      setLoadingPage(true);
      const { data } = await MenuPageService.getMenuPage();
      const response = data.data;

      setMenus(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const getNewsLetters = async () => {
    try {
      setLoadingPage(true);
      const { data } = await NewsLetterSectionsService.getNewsletter();
      const response = data.data;

      setNewsLetters(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setLoadingButton(true);

          await NewsLetterSectionsService.updateNewsletter(values);

          getNewsLetters();

          message.success('Berhasil mengubah data');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    getMenuPages();
    getNewsLetters();

    return () => {
      setMenus([]);
      setNewsLetters([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="rata-cms-newsletter-section pd-cms-form-1">
      <Header>
        <Title>Newsletter Section</Title>
      </Header>

      <Content>
        <Row>
          <Form
            onSubmit={(event) => handleSubmit(event)}
            className="pd-admin-create-form">
            <Form.Item label="Title">
              {getFieldDecorator(FIELDS.TITLE, {
                rules: [
                  { required: true, message: 'Please input title newsletter!' },
                ],
                initialValue: newsLetters[FIELDS.TITLE],
              })(<Input placeholder="Input title newsletter" />)}
            </Form.Item>

            <Form.Item label="Description">
              {getFieldDecorator(FIELDS.DESCRIPTION, {
                rules: [
                  {
                    required: true,
                    message: 'Please input description newsletter!',
                  },
                ],
                initialValue: newsLetters[FIELDS.DESCRIPTION],
              })(
                <Input.TextArea
                  rows={4}
                  placeholder="Input description newsletter"
                />
              )}
            </Form.Item>

            <Form.Item label="Placeholder">
              {getFieldDecorator(FIELDS.PLACEHOLDER, {
                rules: [
                  {
                    required: true,
                    message: 'Please input placeholder newsletter!',
                  },
                ],
                initialValue: newsLetters[FIELDS.PLACEHOLDER],
              })(<Input placeholder="Input placeholder newsletter" />)}
            </Form.Item>

            <Form.Item label="Button Text">
              {getFieldDecorator(FIELDS.BUTTON_TEXT, {
                rules: [
                  {
                    required: true,
                    message: 'Please input button text newsletter!',
                  },
                ],
                initialValue: newsLetters[FIELDS.BUTTON_TEXT],
              })(<Input placeholder="Input button text newsletter" />)}
            </Form.Item>

            <Form.Item label="Menu ( Newsletter Display )">
              {getFieldDecorator(FIELDS.MENU_PAGE_LIST, {
                rules: [
                  {
                    required: true,
                    message:
                      'Please choose the menu to show newsletter placement',
                  },
                ],
                initialValue: newsLetters[FIELDS.MENU_PAGE_LIST],
              })(
                <Select
                  showSearch
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Select newsletter placement"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {menus.length > 0 &&
                    menus.map((menu) => (
                      <Option value={menu.path} key={menu.id}>
                        {menu.title}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-full"
                type="primary"
                htmlType="submit"
                loading={loadingButton}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Content>
    </Layout>
  );
};

export const NewsletterSectionForm = Form.create({
  name: 'validate_newsletter_section',
})(NewsletterSection);

export default NewsletterSection;
