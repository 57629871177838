import React, { useState } from 'react';

import ReadMore from 'components/ReadMore';

import moment from 'moment';

import { Checkbox, Icon, Tooltip } from 'antd';

import './style.scss';

const Summary = ({
  checked,
  onChecked,
  comment = {},
  onChangeStatus,
  isHover = false,
  checkedActive = false,
  showAdmin = false,
  reason = false,
  url = '',
}) => {
  const [showAction, setShowAction] = useState(false);

  const handleHover = () => {
    setShowAction((prevState) => !prevState);
  };

  const handleChangeStatus = (status, item) => {
    onChangeStatus(status, item);
  };

  const generateClassName = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'approved';
      case 'REJECTED':
        return 'rejected';
      case 'ADMIN_RATA':
        return 'admin-rata';
      default:
        return;
    }
  };

  const generateAction = (status) => {
    switch (status) {
      case 'APPROVED':
        return 'Approved';
      case 'REJECTED':
        return 'Rejected';
      case 'ADMIN_RATA':
        return 'Marked as Marketing Comment';
      default:
        return;
    }
  };

  return (
    <div
      className="comment-summary"
      onMouseEnter={() => isHover && handleHover()}
      onMouseLeave={() => isHover && handleHover()}>
      {isHover && checkedActive && (
        <div className="mr-15">
          <Checkbox
            checked={checked}
            onChange={() => onChecked(comment.id)}></Checkbox>
        </div>
      )}

      <div className="full-width">
        <div className="flex-between mb-15">
          <div className="tag-and-admin">
            <div className="tag mr-5">{comment.article_category_key} </div>

            {showAdmin && comment.updated_at && (
              <div className={`admin ${generateClassName(comment.status)}`}>
                <i>
                  {generateAction(comment.status)} by{' '}
                  <b>{comment.admin_name}</b>{' '}
                </i>
                <i>
                  ({moment(comment.updated_at).format('DD-MMMM-YYYY HH:mm:ss')})
                </i>
              </div>
            )}
          </div>

          {showAction ? (
            <div className="action-master">
              <div
                className="icon-container green"
                onClick={() => handleChangeStatus('ADMIN_RATA', comment)}>
                <Tooltip placement="top" title="Mark as Marketing Comment">
                  <Icon type="user-add" />
                </Tooltip>
              </div>
              <div
                className="icon-container red"
                onClick={() => handleChangeStatus('REJECTED', comment)}>
                <Tooltip placement="top" title="Reject Comment">
                  <Icon type="close" />
                </Tooltip>
              </div>
              <div
                className="icon-container green"
                onClick={() => handleChangeStatus('APPROVED', comment)}>
                <Tooltip placement="top" title="Approve Comment">
                  <Icon type="check" />
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className="datetime">
              {moment(comment.created_at).format('DD-MMMM-YYYY HH:mm:ss')}
            </div>
          )}
        </div>

        <p>
          <b className="blue-text">
            {comment.author_name} ({comment.author_email})
          </b>{' '}
          response to a comment by{' '}
          <b className="blue-text">
            {comment.parent && comment.parent.author_name}
          </b>{' '}
          on article
          <a href={url} target="_blank" rel="noopener noreferrer">
            <span className="blue-text">
              &quot;{comment.article_name}&quot;
            </span>
          </a>
        </p>

        <ReadMore text={comment.comment_content} />

        {comment.parent && (
          <div className="replies">
            <p className="datetime">
              {moment(comment.parent.created_at).format(
                'DD-MMMM-YYYY HH:mm:ss'
              )}
            </p>

            <p>
              <b className="blue-text">
                {comment.parent.author_name} ({comment.parent.author_email})
              </b>{' '}
              commented to{' '}
              <a href={url} target="_blank" rel="noopener noreferrer">
                <span className="blue-text">
                  &quot;{comment.parent.article_name}&quot;
                </span>
              </a>
            </p>

            <ReadMore text={comment.parent.comment_content} />
          </div>
        )}

        {reason && <div className="reason">Reject reason: {reason}</div>}
      </div>
    </div>
  );
};

export default Summary;
