import api from './index';

export default {
  updatePageContent(payload) {
    return api.put(`/comparison_pages`, { request: payload });
  },
  getPageContent() {
    return api.get(`/comparison_pages`);
  },
  listcomparisons(q, page, size) {
    let url = '/comparisons';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page && filters.push(`page=${page}`);
    size && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  detailComparisons(id) {
    return api.get(`/comparisons/${id}`);
  },
  createComparisons(payload) {
    return api.post(`/comparisons`, { request: payload });
  },
  updateComparisonsDetail(id, payload) {
    return api.put(`/comparisons/${id}`, { request: payload });
  },
  updateComparisonsOrder(payload) {
    return api.put(`/comparisons`, { request: payload });
  },
  deleteComparisons(id) {
    return api.delete(`/comparisons/${id}`);
  },
};
