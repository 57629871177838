import api from './index';

export default {
  get() {
    return api.get(`floating-promo-banners`);
  },
  create(payload) {
    return api.put(`floating-promo-banners`, {
      request: payload,
    });
  },
  update(id, payload) {
    return api.put(`floating-promo-banners/${id}`, {
      request: payload,
    });
  },
};
