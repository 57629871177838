import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import BlogStoryApi from 'api/v2/blog/story';

import { MONTHS } from 'constants/index';

import SelectedMessage from 'components/blog/selected-message';

import { alertErr } from 'utils';

import {
  Button,
  Col,
  Icon,
  Input,
  Layout,
  Table,
  Typography,
  Popconfirm,
  Row,
  Select,
  Switch,
  message,
} from 'antd';

import moment from 'moment';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Stories = () => {
  const [blogStories, setBlogStories] = useState([]);
  const [search, setSearch] = useState('');
  const [monthFilter, setMonthFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const getStories = async () => {
    try {
      setLoadingPage(true);
      const { data } = await BlogStoryApi.get();
      const response = data.data;

      manipulateDataWithTableProps(response);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await BlogStoryApi.update({ id, is_deleted: true });

      setBlogStories((prevState) => prevState.filter((item) => item.id !== id));
      message.success('Berhasil menghapus story');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleMultipleDelete = async () => {
    const payload = selectedRows.map((row) => {
      row.is_deleted = true;

      return row;
    });

    try {
      await BlogStoryApi.updateMultiple(payload);

      getStories();
      setSelectedRows([]);
      message.success('Berhasil menghapus promo');
    } catch (err) {
      alertErr(err);
    }
  };

  const handleChange = async (key, data, id, checkedValue) => {
    try {
      if (key === 'is_priority' && !checkedValue) {
        await BlogStoryApi.updatePriority({ id, [key]: !checkedValue });
      } else {
        await BlogStoryApi.update({ id, [key]: !checkedValue });
      }

      const temp = data.slice();

      const updatedStories = temp.map((story) => {
        if (story.id === id) {
          story[key] = !story[key];
        }

        return story;
      });

      manipulateDataWithTableProps(updatedStories);
      message.success('Berhasil mengubah story');
    } catch (err) {
      alertErr(err);
    }
  };

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum) => {
      datum.key = datum.id;

      datum.is_highlight_action = (
        <Switch
          checked={datum.is_priority}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_priority', data, datum.id, datum.is_priority)
          }
        />
      );

      datum.is_active_action = (
        <Switch
          checked={datum.is_active}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() =>
            handleChange('is_active', data, datum.id, datum.is_active)
          }
        />
      );

      datum.date = moment(datum.updated_at).format('DD MMMM YYYY');

      datum.action = (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/reviews-and-promos/stories/edit/${datum.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <Popconfirm
              title="Are you sure？"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => handleDelete(datum.id)}>
              <Icon type="delete" />
            </Popconfirm>
          </div>
        </div>
      );
    });

    setBlogStories(data);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Publish Date',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: 'Highlight Top 4',
      dataIndex: 'is_highlight_action',
      key: 'is_highlight_action',
    },
    {
      title: 'Publish',
      dataIndex: 'is_active_action',
      key: 'is_active_action',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const { Search } = Input;
  const { Option } = Select;

  const handleChangeMonth = (value) => setMonthFilter(value);
  const handleChangeStatus = (value) => setStatusFilter(value);

  const handleSearch = async (value) => {
    const { data } = await BlogStoryApi.get(value, monthFilter, statusFilter);
    const response = data.data;

    setSearch(value);
    manipulateDataWithTableProps(response);
  };

  const handleFilter = async () => {
    const { data } = await BlogStoryApi.get(search, monthFilter, statusFilter);
    const response = data.data;

    manipulateDataWithTableProps(response);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (key, value) => {
      setSelectedRows(value);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    getStories();

    return () => setBlogStories([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="rata-cms-review-and-promo-stories pd-cms-form-1">
      <Header>
        <Title>Cerita Pengguna Rata</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Link to="/cms/reviews-and-promos/Stories/create">
              <Button type="primary">Upload Photo Before After</Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={11}>
            <div className="label">Search by</div>
            <Search onSearch={(value) => handleSearch(value)} />
          </Col>
          <Col xs={13}>
            <div className="label">Filter by</div>

            <Row gutter={[12, 0]}>
              <Col xs={8}>
                <Select
                  defaultValue="All Month"
                  onChange={handleChangeMonth}
                  style={{ width: '100%' }}>
                  {MONTHS.map((month, id) => (
                    <Option key={id} value={month.number}>
                      {month.name}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col xs={8}>
                <Select
                  defaultValue="All Status"
                  onChange={handleChangeStatus}
                  style={{ width: '100%' }}>
                  <Option value="">All Status</Option>
                  <Option value="true">Active</Option>
                  <Option value="false">Unactive</Option>
                </Select>
              </Col>

              <Col xs={8}>
                <Button
                  onClick={handleFilter}
                  type="primary"
                  ghost
                  style={{ width: '100%' }}>
                  Apply Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              rowSelection={rowSelection}
              dataSource={blogStories}
              columns={columns}
              loading={loadingPage}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <SelectedMessage
              handleMultipleDelete={handleMultipleDelete}
              rows={selectedRows.length}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Stories;
