import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import FormServices from 'api/forms';

import {
  Button,
  Input,
  Form,
  Layout,
  Typography,
  Row,
  Select,
  message,
  // Switch,
  Icon,
} from 'antd';

import { FIELDS, TYPES } from '../constant';

import '../style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const CreateEditFormQuestion = ({ form }) => {
  const { formId, questionId } = useParams();
  const history = useHistory();

  const [loadingButton, setLoadingButton] = useState(false);
  const [content, setContent] = useState({});
  const [formObject, setFormObject] = useState({});

  const { getFieldDecorator, getFieldValue, resetFields } = form;

  const getSingleQuestion = async () => {
    const response = await FormServices.getQuestionById(formId, questionId);

    const { data } = response.data;

    setContent(data);
  };

  const getSingleForm = async () => {
    const response = await FormServices.getFormById(formId);

    const { data } = response.data;

    setFormObject(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const payload = {
          ...values,
          choices:
            getFieldValue(FIELDS.TYPE) === 'SINGLE_CHOICE'
              ? values.choices
              : null,
          extra_answer: false,
        };

        try {
          setLoadingButton(true);

          if (questionId)
            await FormServices.updateFormQuestion(formId, questionId, payload);
          else await FormServices.createFormQuestion(formId, payload);

          message.success(
            `Berhasil ${questionId ? 'mengubah' : 'membuat'} form`
          );

          history.push(`/cms/forms/${formId}/questions`);
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        }
      }
    });
  };

  useEffect(() => {
    formId && questionId && getSingleQuestion();

    return () => setContent({});
  }, [formId, questionId]);

  useEffect(() => {
    formId && getSingleForm();

    return () => setFormObject({});
  }, [formId]);

  useEffect(() => {
    resetFields('choices');
  }, [getFieldValue(FIELDS.TYPE)]);

  return (
    <Layout className="rata-cms-promo-floating-banners pd-cms-form-1">
      <Header>
        <Title>
          <Icon type="caret-left" onClick={() => history.goBack()} />
          {questionId ? 'Edit' : 'Create'} Form Question
        </Title>
      </Header>

      <Content>
        <Row>
          <Form
            onSubmit={(event) => handleSubmit(event)}
            className="pd-admin-create-form">
            <Form.Item label="Question">
              {getFieldDecorator(FIELDS.QUESTION, {
                rules: [
                  { required: true, message: 'Please input the question!' },
                ],
                initialValue: content[FIELDS.QUESTION] || '',
              })(<Input placeholder="Input the question form" />)}
            </Form.Item>

            <Form.Item label="Type">
              {getFieldDecorator(FIELDS.TYPE, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the type!',
                  },
                ],
                initialValue: content[FIELDS.TYPE],
              })(
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {TYPES.length > 0 &&
                    TYPES.map((type) => (
                      <Option value={type.value} key={type.id}>
                        {type.value}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>

            {/* getFieldValue(FIELDS.TYPE) === 'MULTI_CHOICE' */}

            {getFieldValue(FIELDS.TYPE) === 'SINGLE_CHOICE' ? (
              <Form.Item label="Choice">
                {getFieldDecorator(FIELDS.CHOICES, {
                  rules: [
                    {
                      required: true,
                      message: 'Please input the choice!',
                    },
                  ],
                  initialValue: content[FIELDS.CHOICES],
                })(<Input placeholder="Input choice" />)}
              </Form.Item>
            ) : null}

            {/* {getFieldValue(FIELDS.TYPE) === 'SINGLE_CHOICE' ||
            getFieldValue(FIELDS.TYPE) === 'MULTI_CHOICE' ? (
              <Form.Item label="Extra Answer">
                {getFieldDecorator(FIELDS.EXTRA_ANSWER, {
                  valuePropName: 'checked',
                  initialValue: content[FIELDS.EXTRA_ANSWER] || false,
                })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
              </Form.Item>
            ) : null} */}

            <Form.Item label="Field">
              {getFieldDecorator(FIELDS.FIELD, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the field!',
                  },
                ],
                initialValue: content[FIELDS.FIELD],
              })(
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select field"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  {formObject.field_list &&
                    formObject.field_list.map((field, idx) => (
                      <Option value={field} key={idx}>
                        {field}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-full"
                type="primary"
                htmlType="submit"
                loading={loadingButton}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Content>
    </Layout>
  );
};

export const CreateEditFormQuestionAppointment = Form.create({
  name: 'validate_create_edit_form_question',
})(CreateEditFormQuestion);

export default CreateEditFormQuestion;
