import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ClinicApi from 'api/clinic';

import { alertErr } from 'utils';

import {
  Button,
  Col,
  Icon,
  Layout,
  Pagination,
  Table,
  Typography,
  Row,
  Switch,
  Modal,
  message,
} from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

const Promos = () => {
  const [promos, setPromos] = useState([]);
  const [pages, setPages] = useState({
    total_data: 0,
    current_page: 1,
    next_page: 0,
    prev_page: 0,
    total_pages: 1,
  });
  const [loading, setLoading] = useState(false);

  const fetchClinic = async () => {
    try {
      setLoading(true);
      const { data } = await ClinicApi.get();
      const response = data.data;

      const total_data = data.total_data;
      const current_page = data.current_page;
      const next_page = data.next_page;
      const prev_page = data.prev_page;
      const total_pages = data.total_pages;

      setPages({
        total_data,
        current_page,
        next_page,
        prev_page,
        total_pages,
      });

      setPromos(response);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitched = async (v) => {
    try {
      const payload = {
        ...v,
        is_publish: !v.is_publish,
      };
      await ClinicApi.updateStatus(payload, v.id);
      message.success('Berhasil mengubah status klinik');
      fetchClinic();
    } catch (err) {
      alertErr(err);
    }
  };

  const showDeleteConfirm = (id, title) => {
    confirm({
      title: 'Delete This Item?',
      content: `Are you sure to delete "${title}"`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      await ClinicApi.delete(id);

      message.success('Berhasil menghapus klinik');

      fetchClinic();
    } catch (err) {
      alertErr(err);
    }
  };

  useEffect(() => {
    fetchClinic();

    return () => setPromos([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Clinic Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Location',
      dataIndex: 'location_name',
      sorter: (a, b) => a.location_name.length - b.location_name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      sorter: (a, b) => a.phone - b.phone,
      sortDirections: ['descend', 'ascend'],
      render: (v) => (v !== '' ? v : '-'),
    },
    {
      title: 'Publish',
      dataIndex: 'is_publish',
      key: 'is_publish',
      render: (v, record) => (
        <Switch
          checked={v}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleSwitched(record)}
        />
      ),
    },
    {
      title: 'Action',
      render: (v) => {
        return (
          <React.Fragment>
            <div className="action-icon">
              <div className="edit" style={{ marginRight: 20 }}>
                <Link to={`/cms/clinics/manage/edit/${v.id}`}>
                  <Icon type="edit" />
                </Link>
              </div>

              <div className="edit">
                <Link to={`/cms/clinics/manage/${v.id}/history`}>
                  <Icon type="history" />
                </Link>
              </div>

              <div
                className="delete"
                onClick={() => showDeleteConfirm(v.id, v.name)}>
                <Icon type="delete" />
              </div>
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const handleChangePage = async (value) => {
    try {
      pages.current_page = value;
      setPages({ ...pages });

      setLoading(true);

      const { data } = await ClinicApi.get(value);

      const response = data.data;
      setPromos(response);
    } catch (error) {
      alertErr(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>Clinic Rata</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Link to="/cms/clinics/manage/create">
              <Button type="primary">Create New Clinic</Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={promos}
              columns={columns}
              pagination={false}
              loading={loading}
            />

            {promos.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={pages.current_page}
                  onChange={handleChangePage}
                  total={pages.total_data}
                />
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Promos;
