import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ArticleApi from 'api/v3/article';
import ReportApi from 'api/report';

import moment from 'moment';

import { alertErr } from 'utils';

import { CATEGORIES, DATE_PERIODE_OPTIONS } from 'constants/index';

import SelectedMessage from 'components/blog/selected-message';

import {
  Button,
  Col,
  Icon,
  Input,
  Layout,
  Pagination,
  Popconfirm,
  Table,
  Typography,
  Row,
  Select,
  Switch,
  message,
  DatePicker,
} from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Promos = () => {
  const [articles, setArticles] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 0,
    total_data: 0,
  });
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [categoryFilter, setCategoryFilter] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [periode, setPeriode] = useState(undefined);
  const [datePeriode, setDatePeriode] = useState({
    start_date: '',
    end_date: '',
  });
  const [sort, setSort] = useState({
    by: '',
    direction: '',
  });

  const columns = [
    {
      title: 'Promo Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      width: 200,
    },
    {
      title: 'Category',
      dataIndex: 'article_category',
      key: 'article_category',
      sorter: true,
      render: (article_category) => <div>{formatingTag(article_category)}</div>,
      width: 150,
    },
    {
      title: 'Publish',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 100,
      render: (v, record) => (
        <Switch
          checked={v}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleSwitched('set_active', record, 'is_active')}
        />
      ),
    },
    {
      title: 'Popular Post',
      dataIndex: 'is_popular',
      key: 'is_popular',
      width: 120,
      render: (v, record) => (
        <Switch
          checked={v}
          disabled={record.is_active ? false : true}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleSwitched('set_popular', record, 'is_popular')}
        />
      ),
    },
    {
      title: 'Show in Article Page',
      dataIndex: 'is_priority',
      key: 'is_priority',
      width: 170,
      render: (v, record) => (
        <Switch
          checked={v}
          disabled={record.is_active ? false : true}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={() => handleSwitched('set_priority', record, 'is_priority')}
        />
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => {
        const created_date = moment(record.created_at).format('DD MMMM YYYY');
        return created_date;
      },
      sorter: true,
      width: 170,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, record) => {
        const updated_date = moment(record.updated_at).format('DD MMMM YYYY');
        return updated_date;
      },
      sorter: true,
      width: 170,
    },
    {
      title: 'Action',
      width: 150,
      render: (v, record) => {
        return (
          <div className="action-icon">
            <div className="edit" style={{ marginRight: 20 }}>
              <Link to={`/cms/articles/edit/${record.id}`}>
                <Icon type="edit" />
              </Link>
            </div>

            <div className="edit">
              <Link to={`/cms/articles/${record.id}/history`}>
                <Icon type="history" />
              </Link>
            </div>

            <div className="delete">
              <Popconfirm
                title="Are you sure？"
                icon={
                  <Icon type="question-circle-o" style={{ color: 'red' }} />
                }
                onConfirm={() => handleDelete(record.id)}>
                <Icon type="delete" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const getArticles = async (
    q,
    category,
    start_date,
    end_date,
    status,
    page = 1,
    sort_by,
    sort_dir
  ) => {
    try {
      setIsLoading(true);

      const { data } = await ArticleApi.get(
        q,
        category,
        start_date,
        end_date,
        status,
        page,
        sort_by,
        sort_dir
      );

      setPagination({
        ...pagination,
        current_page: data.current_page,
        total_data: data.total_data,
        total_page: data.total_page,
      });

      const ARTICLES = data.data;

      setArticles(ARTICLES);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePeriode = (val) => {
    setPeriode(val);

    let start_date, end_date;

    switch (val) {
      case 'Today':
        start_date = moment().format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'This Week':
        start_date = moment().startOf('isoWeek').format('YYYY-MM-DD');
        end_date = moment().endOf('isoWeek').format('YYYY-MM-DD');
        break;
      case 'Last 7 Days':
        start_date = moment().subtract(6, 'days').format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'Last 30 Days':
        start_date = moment().subtract(29, 'days').format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'Last 3 Months':
        start_date = moment()
          .subtract(3, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        end_date = moment().format('YYYY-MM-DD');
        break;
      case 'This Month':
        start_date = moment().startOf('month').format('YYYY-MM-DD');
        end_date = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case 'This Year':
        start_date = moment().startOf('year').format('YYYY-MM-DD');
        end_date = moment().endOf('year').format('YYYY-MM-DD');
        break;
      default:
        break;
    }

    setDatePeriode({
      ...datePeriode,
      start_date,
      end_date,
    });
  };

  const handleSwitched = async (query, data, key) => {
    try {
      await ArticleApi.updateStatus(`${query}=${!data[key]}`, data.id);
      message.success('Berhasil mengubah artikel');
      getArticles('', '', '', '', '', 1, sort.by, sort.direction);
    } catch (err) {
      alertErr(err);
    } finally {
      setSearch('');
      setStatusFilter(undefined);
      setCategoryFilter(undefined);
      setPeriode(undefined);
      setDatePeriode({ ...datePeriode, start_date: '', end_date: '' });
    }
  };

  const handleDelete = async (id) => {
    try {
      await ArticleApi.delete(id);
      getArticles('', '', '', '', '', 1, sort.by, sort.direction);
      message.success('Berhasil menghapus artikel');
    } catch (err) {
      alertErr(err);
    } finally {
      setSearch('');
      setStatusFilter(undefined);
      setCategoryFilter(undefined);
      setPeriode(undefined);
      setDatePeriode({ ...datePeriode, start_date: '', end_date: '' });
    }
  };

  const handleMultipleDelete = async () => {
    const payload = selectedRows.map((row) => {
      row.is_deleted = true;

      return row;
    });

    try {
      await ArticleApi.updateMultiple(payload);
      getArticles(
        '',
        '',
        datePeriode.start_date,
        datePeriode.end_date,
        '',
        pagination.current_page
      );
      setSelectedRows([]);
      message.success('Berhasil menghapus artikel');
    } catch (err) {
      alertErr(err);
    }
  };

  const formatingTag = (value) => {
    if (!value) return '';
    return value
      .replaceAll('-', ' ')
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  };

  const onChangeTable = (_p, _f, pSorter) => {
    setSort({
      by: pSorter?.order ? pSorter.field : undefined,
      direction: pSorter?.order
        ? pSorter?.order === 'ascend'
          ? 'asc'
          : 'desc'
        : undefined,
    });

    getArticles(
      search,
      categoryFilter,
      datePeriode.start_date,
      datePeriode.end_date,
      statusFilter,
      pagination.current_page,
      pSorter?.order ? pSorter.field : undefined,
      pSorter?.order
        ? pSorter?.order === 'ascend'
          ? 'asc'
          : 'desc'
        : undefined
    );
  };

  const handleFilter = () => {
    getArticles(
      search,
      categoryFilter,
      datePeriode.start_date,
      datePeriode.end_date,
      statusFilter,
      1,
      sort.by,
      sort.direction
    );
  };

  const handlePagination = (value) => {
    getArticles(
      search,
      categoryFilter,
      datePeriode.start_date,
      datePeriode.end_date,
      statusFilter,
      value,
      sort.by,
      sort.direction
    );
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_, value) => {
      setSelectedRows(value);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const exportToCSV = async () => {
    const { data } = await ReportApi.articles(
      'xlsx',
      search,
      categoryFilter,
      datePeriode.start_date,
      datePeriode.end_date,
      statusFilter
    );

    const link = document.createElement('a');
    link.href = data.data.url;
    link.download = data.data.file_name;
    link.click();
    message.success('Data berhasil diunduh');
  };

  useEffect(() => {
    getArticles('', '', '', '', '', 1);

    return () => setArticles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>Articles</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={12}>
            <Link to="/cms/articles/create">
              <Button type="primary">Create New Article</Button>
            </Link>
          </Col>
          <Col xs={12} style={{ textAlign: 'end' }}>
            <Button type="primary" onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>
        <Row gutter={[12, 0]}>
          <Col
            style={{
              width: periode === 'Custom Range' ? '20%' : '25%',
              display: 'inline-block',
            }}>
            <div className="label" style={{ marginBottom: 8 }}>
              Search
            </div>
            <Search
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search by keyword"
              value={search}
              allowClear
            />
          </Col>
          <Col
            style={{
              width: periode === 'Custom Range' ? '20%' : '25%',
              display: 'inline-block',
            }}>
            <div className="label" style={{ marginBottom: 8 }}>
              Status Publish
            </div>
            <Select
              onChange={(v) => setStatusFilter(v)}
              style={{ width: '100%' }}
              placeholder="Select Status Publish"
              allowClear
              value={statusFilter}>
              <Option value="true">Active</Option>
              <Option value="false">Not Active</Option>
            </Select>
          </Col>
          <Col
            style={{
              width: periode === 'Custom Range' ? '20%' : '25%',
              display: 'inline-block',
            }}>
            <div className="label" style={{ marginBottom: 8 }}>
              Category
            </div>
            <Select
              onChange={(v) => setCategoryFilter(v)}
              style={{ width: '100%' }}
              allowClear
              placeholder="Select Category"
              value={categoryFilter}>
              {CATEGORIES.map((category) => (
                <Option key={category.id} value={category.key}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col
            style={{
              width: periode === 'Custom Range' ? '20%' : '25%',
              display: 'inline-block',
            }}>
            <div className="label" style={{ marginBottom: 8 }}>
              Period
            </div>
            <Select
              onChange={(v) => handleChangePeriode(v)}
              style={{ width: '100%' }}
              placeholder="Select Period"
              allowClear
              value={periode}>
              {DATE_PERIODE_OPTIONS.map((periode, idx) => (
                <Option value={periode.value} key={idx}>
                  {periode.label}
                </Option>
              ))}
            </Select>
          </Col>
          {periode === 'Custom Range' && (
            <Col
              style={{
                width: periode === 'Custom Range' ? '20%' : '25%',
                display: 'inline-block',
              }}>
              <div className="label" style={{ marginBottom: 8 }}>
                Custom Range
              </div>
              <DatePicker.RangePicker
                format="YYYY-MM-DD"
                value={[
                  datePeriode.start_date
                    ? moment(datePeriode.start_date, 'YYYY-MM-DD')
                    : '',
                  datePeriode.end_date
                    ? moment(datePeriode.end_date, 'YYYY-MM-DD')
                    : '',
                ]}
                onChange={(_, dateString) => {
                  const [start_date, end_date] = dateString;

                  setDatePeriode({
                    ...datePeriode,
                    start_date: start_date
                      ? moment(start_date).format('YYYY-MM-DD')
                      : '',
                    end_date: end_date
                      ? moment(end_date).format('YYYY-MM-DD')
                      : '',
                  });
                }}
              />
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          <Col
            style={{
              width: periode === 'Custom Range' ? '20%' : '25%',
              marginLeft: periode === 'Custom Range' ? '80%' : '75%',
            }}>
            <Button
              onClick={handleFilter}
              type="primary"
              ghost
              style={{ width: '100%', marginTop: 30 }}>
              Apply
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              rowSelection={rowSelection}
              dataSource={articles}
              columns={columns}
              pagination={false}
              scroll={{ x: 1000 }}
              loading={isLoading}
              rowKey="id"
              onChange={onChangeTable}
            />

            {articles.length > 0 && (
              <div className="pagination">
                <Pagination
                  current={pagination.current_page}
                  onChange={handlePagination}
                  total={pagination.total_data}
                />
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <SelectedMessage
              handleMultipleDelete={handleMultipleDelete}
              rows={selectedRows.length}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Promos;
