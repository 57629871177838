import { message } from 'antd';
import _ from 'lodash';

/**
 * @param  {object} antFormError
 * @return  {boolean}
 */

export function hasFormErrors(antFormError) {
  return Object.keys(antFormError).some((field) => antFormError[field]);
}

/**
 * @param  {object} error
 * @param  {string} defaultMessage - fallback message when API doesn't give an error message
 */
export function alertErr(
  err,
  defaultMessage = 'connection error, check your connection!'
) {
  const errMessage = _.get(err, 'response.data.message');
  message.error(errMessage || defaultMessage);
}

export const getHashOfString = (str) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);

  return hash;
};

export const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

export const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

export const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

export const getInitials = (user) => {
  let username = '';

  const names = user.split(' ');
  if (names.length >= 2)
    username = `${names[0].substring(0, 1)}${names[1].substring(0, 1)}`;
  if (names.length === 1) username = names[0].substring(0, 2);

  return username.toUpperCase();
};

export const setValue = (functionFor) => {
  return (e) => {
    const value = parseInt(e.target.value);
    functionFor(value);
  };
};

export const getRange = (value, range) => {
  return [Math.max(0, value - range), Math.min(value + range, 100)];
};

export const capitalize = (str) => {
  if (!str) return '';

  const arr = str.split('_').join(' ').toLowerCase();
  const result = arr.split(' ');

  for (var i = 0; i < result.length; i++) {
    result[i] = result[i].charAt(0).toUpperCase() + result[i].slice(1);
  }

  return result.join(' ');
};

export const textReplacement = (str) => {
  if (!str) return '';

  switch (str) {
    case 'CREATED':
      return 'Created';
    case 'DELETED':
      return 'Deleted';

    default:
      return str;
  }
};

export const convertToSlug = (text) => {
  return text
    ?.toLowerCase()
    ?.replace(/ /g, '-')
    ?.replace(/[^\w-]+/g, '');
};

export const phoneRegExp = /^(\||)8[1-9][0-9]{6,9}$/;

export const slugRegExp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

export const onlyPositiveNumber = /^[0-9]{1,3}$/;
