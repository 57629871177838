import api from './index';

export default {
  get() {
    return api.get(`quiz/intro-contents`);
  },
  create(payload) {
    return api.post(`quiz/intro-contents`, { request: payload });
  },
  update(payload) {
    return api.put(`quiz/intro-contents`, { request: payload });
  },
};
