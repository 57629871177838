import api from './index';

export default {
  getHistories(id, page) {
    return api.get(`histories?menu_type_id=${id}&page=${page}&size=10`);
  },
  getHistoryById(id) {
    return api.get(`histories/${id}`);
  },
};
