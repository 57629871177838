import api from './index';

export default {
  getBuyingStep() {
    return api.get(`/buying_step_pages`);
  },
  updateBuyingStepPage(payload) {
    return api.put(`/buying_step_pages`, { request: payload });
  },
};
