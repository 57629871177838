import api from './index';

export default {
  getAllTracking() {
    return api.get('/tracking-events');
  },
  updateTracking(payload) {
    return api.put('/tracking-events', payload);
  },
};
