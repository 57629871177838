export const FIELDS = {
  QUESTION: 'question',
  TYPE: 'type',
  CHOICES: 'choices',
  EXTRA_ANSWER: 'extra_answer',
  FIELD: 'field',
};

export const TYPES = [
  {
    id: 1,
    value: 'TEXT',
  },
  {
    id: 2,
    value: 'EMAIL',
  },
  {
    id: 3,
    value: 'PHONE',
  },
  {
    id: 4,
    value: 'SINGLE_CHOICE',
  },
  // {
  //   id: 5,
  //   value: 'MULTI_CHOICE',
  // },
  {
    id: 6,
    value: 'DATE',
  },
  // {
  //   id: 7,
  //   value: 'TIME',
  // },
  // {
  //   id: 8,
  //   value: 'BOOLEAN',
  // },
];
