const MONTHS = Object.freeze([
  {
    name: 'All Month',
    number: 0,
  },
  {
    name: 'January',
    number: 1,
  },
  {
    name: 'February',
    number: 2,
  },
  {
    name: 'March',
    number: 3,
  },
  {
    name: 'April',
    number: 4,
  },
  {
    name: 'May',
    number: 5,
  },
  {
    name: 'June',
    number: 6,
  },
  {
    name: 'July',
    number: 7,
  },
  {
    name: 'August',
    number: 8,
  },
  {
    name: 'September',
    number: 9,
  },
  {
    name: 'October',
    number: 10,
  },
  {
    name: 'November',
    number: 11,
  },
  {
    name: 'Desember',
    number: 12,
  },
]);

const CATEGORIES = Object.freeze([
  {
    id: 1,
    name: 'Aligner Gigi 101',
    key: 'aligner-gigi-101',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/aligner-information-icon.svg',
  },
  {
    id: 2,
    name: 'Petunjuk Pemakaian Aligner',
    key: 'petunjuk-pemakaian-aligner',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/aligner-user-guide-icon.svg',
  },
  {
    id: 3,
    name: 'Review Rata',
    key: 'review-rata',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/real-stories-icon.svg',
  },
  {
    id: 4,
    name: 'Kesehatan Gigi dan Mulut',
    key: 'kesehatan-gigi-dan-mulut',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/dental-health-icon.svg',
  },
  {
    id: 5,
    name: 'Komunitas Rata',
    key: 'komunitas-rata',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/our-community-icon.svg',
  },
  {
    id: 6,
    name: 'News',
    key: 'news',
    media_url:
      'https://rata-web-production-assets.s3.ap-southeast-1.amazonaws.com/category-icons/updates-icon.svg',
  },
]);

const DATE_PERIODE_OPTIONS = [
  { label: 'Today', value: 'Today' },
  { label: 'Last 7 Days', value: 'Last 7 Days' },
  { label: 'Last 30 Days', value: 'Last 30 Days' },
  { label: 'This Week', value: 'This Week' },
  { label: 'This Month', value: 'This Month' },
  { label: 'This Year', value: 'This Year' },
  { label: 'Custom Range', value: 'Custom Range' },
];

export { MONTHS, CATEGORIES, DATE_PERIODE_OPTIONS };
