import api from './index';

export default {
  footer() {
    return api.get(`/reports/newsletter-footers`);
  },

  promotion() {
    return api.get(`/reports/newsletter-promotions`);
  },

  oldQuiz() {
    return api.get(`/reports/old-quizzes`);
  },

  newQuiz() {
    return api.get(`/reports/new-quizzes`);
  },

  articles(format = '', q, category, start_date, end_date, status) {
    let url = '/reports/articles';
    const filters = [];

    format.trim() !== '' && filters.push(`format=${format}`);
    q.trim() !== '' && filters.push(`q=${q}`);
    category && filters.push(`article_category=${category}`);
    start_date && filters.push(`start_date=${start_date}`);
    end_date && filters.push(`end_date=${end_date}`);
    status && filters.push(`status=${status}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
};
