import api from './index';

export default {
  getNewsletter() {
    return api.get('/newsletter-sections');
  },
  updateNewsletter(payload) {
    return api.put('/newsletter-sections', { request: payload });
  },
};
