import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import moment from 'moment';

import FormServices from 'api/forms';

import { alertErr } from 'utils';
import { useDebounce } from 'hooks/useDebounce';

import {
  Button,
  Col,
  Icon,
  Layout,
  Pagination,
  Table,
  Typography,
  Row,
  message,
  Popconfirm,
  Input,
} from 'antd';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const SIZE = 10;

const Questions = () => {
  const { formId } = useParams();

  const [questions, setQuestions] = useState([]);
  const [formObject, setFormObject] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);

  const debounced = useDebounce(keyword);

  const queries = {
    formId,
    q: debounced,
    page,
    size: SIZE,
  };

  const getAllQuestion = async () => {
    try {
      const response = await FormServices.getQuestions(queries);

      setQuestions(response.data);
    } finally {
      setLoadingPage(false);
    }
  };

  const getSingleForm = async () => {
    try {
      const response = await FormServices.getFormById(formId);

      const { data } = response.data;

      setFormObject(data);
    } finally {
      setLoadingPage(false);
    }
  };

  const handleDelete = async (question, id) => {
    try {
      await FormServices.deleteQuestion(formId, id);

      message.success(`Berhasil menghapus question ${question}`);

      getAllQuestion();
    } catch (err) {
      alertErr(err);
    }
  };

  const handleKeyword = (e) => setKeyword(e.target.value);

  const handlePagination = (e) => {
    setPage(e);
  };

  const columns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      width: 200,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 150,
    },
    {
      title: 'Choices',
      dataIndex: 'choices',
      key: 'choices',
      width: 200,
      render: (_, record) => (
        <>{record.choices === null ? '-' : record.choices}</>
      ),
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      width: 150,
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => {
        const publish_date = moment(record.created_at).format('DD MMMM YYYY');
        return publish_date;
      },
      width: 170,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, record) => {
        const updated_date = moment(record.updated_at).format('DD MMMM YYYY');
        return updated_date;
      },
      width: 170,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="action-icon">
          <div className="edit">
            <Link to={`/cms/forms/${formId}/questions/edit/${record.id}`}>
              <Icon type="edit" />
            </Link>
          </div>

          <div className="delete">
            <Popconfirm
              title="Are you sure?"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => handleDelete(record.question, record.id)}>
              <Icon type="delete" />
            </Popconfirm>
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  useEffect(() => {
    getAllQuestion();

    return () => setQuestions([]);
  }, [page, debounced]);

  useEffect(() => {
    getSingleForm();

    return () => setFormObject({});
  }, []);

  return (
    <Layout className="rata-cms-review-and-promo-promos pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/forms">
            <Icon type="caret-left" />
          </Link>
          Questions {formObject ? `- ${formObject.purpose}` : null}{' '}
        </Title>
      </Header>

      <Content>
        <Row align="middle">
          <Col lg={12} xs={24}>
            <Link to={`/cms/forms/${formId}/questions/create`}>
              <Button type="primary">Create New Question</Button>
            </Link>
          </Col>
          <Col lg={12} xs={24}>
            <Input placeholder="Search Question" onChange={handleKeyword} />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={questions.data}
              columns={columns}
              pagination={false}
              scroll={{ x: 1000 }}
              loading={loadingPage}
              rowKey="id"
            />

            {questions.data && questions.data.length > 0 ? (
              <div className="pagination">
                <Pagination
                  current={questions.current_page}
                  onChange={handlePagination}
                  pageSize={SIZE}
                  total={questions.total_data}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Questions;
