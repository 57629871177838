import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import TestimoniesApi from 'api/v4/testimony';

import { PageSpinner } from 'components/page-spinner';

import '../style.scss';
import { PdUploader } from 'components/pd-uploader';
import { hasFormErrors } from 'utils';
import ReactQuill from 'react-quill';

const { Header, Content } = Layout;
const { Title } = Typography;

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'link',
];

export function TestimoniesCreateEdit({ form, match, history }) {
  const [testimonies, setTestimonies] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    async function getTestimoniesDetail() {
      setLoadingPage(true);
      try {
        const { data } = await TestimoniesApi.getDetailTestimony(id);
        const testimoniesData = data.data;

        setTestimonies(testimoniesData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getTestimoniesDetail();
    }
  }, [id]);

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!testimonies.media_url) {
        message.error('Picture must be filled');
        return;
      }

      if (!err) {
        try {
          setLoadingButton(true);

          const payload = {
            ...testimonies,
            ...values,
          };

          if (id) {
            await TestimoniesApi.updateTestimonies(id, payload);
            message.success('Data has been updated');
          } else {
            await TestimoniesApi.createTestimonies(payload);
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/testimonies/manage',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/testimonies/manage">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Testimonies
        </Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                { required: true, message: 'Please input name!' },
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Name should not start or end characters with spaces',
                },
              ],
              initialValue: testimonies['name'] || '',
            })(<Input placeholder="Write your name here" />)}
          </Form.Item>

          <Form.Item label="Image - 2:3 (250x270)" required>
            <PdUploader
              purpose="testimonies"
              imgUrl={_.get(testimonies, 'media_url', '')}
              afterUpload={(url) => {
                setTestimonies((prevState) => ({
                  ...prevState,
                  media_url: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Image Alt Text">
            {getFieldDecorator('media_alt_text', {
              rules: [
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Image ALT should not start or end characters with spaces',
                },
              ],
              initialValue: testimonies['media_alt_text'] || '',
            })(<Input placeholder="Please enter image alt text" />)}
          </Form.Item>

          <Form.Item label="Message">
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Please input message!' }],
              initialValue: testimonies['message'] || '',
            })(
              <ReactQuill
                placeholder="Write your message here"
                modules={modules}
                formats={formats}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const TestimoniesCreateEditForm = Form.create({
  name: 'validate_testimonies_edit',
})(TestimoniesCreateEdit);

export default { TestimoniesCreateEdit };
