import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Layout,
  Typography,
  Button,
  message,
  Modal,
  Pagination,
} from 'antd';

import AdminApi from 'api/admin';
import { PageSpinner } from 'components/page-spinner';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export function AdminList() {
  const [admins, setAdmins] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 0,
    total_data: 0,
  });

  let currentAdmin = localStorage.getItem('current_admin');

  function deleteAdmin(id) {
    confirm({
      title: 'Are you sure delete this admin?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        async function deletingAdmin() {
          try {
            await AdminApi.deleteAdmin(id);
            message.success('Berhasil menghapus admin');
            let filterAdmin = admins.filter((value) => {
              return value.id !== id;
            });
            setAdmins(filterAdmin);
          } catch (err) {
            if (err.response.data.message) {
              const errMessage = err.response.data.message;
              message.error(errMessage);
            } else {
              message.error('Tidak dapat menghubungi server, cek koneksi');
            }
          }
        }
        deletingAdmin();
      },
      onCancel() {},
    });
  }

  useEffect(() => {
    let currentAdmin = localStorage.getItem('current_admin');
    function findCurrentAdmin(admin) {
      return admin.email === currentAdmin;
    }

    async function getAdminList() {
      try {
        const { data } = await AdminApi.listAdmin(pagination.current_page);
        setPagination({
          ...pagination,
          total_page: data.total_page,
          total_data: data.total_data,
        });
        let adminList = data.data;
        let current_admin = adminList.find(findCurrentAdmin);
        let filterAdmin = adminList.filter((value) => {
          return value !== current_admin;
        });

        adminList = [current_admin, ...filterAdmin];

        const resultAdmList = adminList.filter(
          (adm) => typeof adm !== 'undefined'
        );

        setAdmins(resultAdmList);
      } finally {
        setLoadingPage(false);
      }
    }

    getAdminList();
  }, [pagination.current_page]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-home-admin pd-cms-form-1">
      <Header>
        <Title>Administrator: Admins</Title>
        <Button className="btn-font" type="primary">
          <Link to={'/cms/admin/create'}>Add New Admin</Link>
        </Button>
      </Header>
      <Content>
        {admins.map((admin) => (
          <>
            {admin.status === 'active' && (
              <div key={admin.id} className="cms-list">
                <Row type="flex" align="middle">
                  <Col xs={7}>
                    <div className="container--center container--no-height">
                      <div>
                        <strong>{admin.name}</strong>
                      </div>
                      <div>{admin.email}</div>
                    </div>
                  </Col>
                  <Col xs={{ span: 4, offset: 13 }}>
                    {admin.email === currentAdmin ? (
                      <div className="btn-layout">
                        <Button className="btn-full" type="primary">
                          <Link
                            to={{
                              pathname: `/cms/admin/edit?id=${admin.id}`,
                              data: { admin },
                            }}>
                            Edit
                          </Link>
                        </Button>
                      </div>
                    ) : (
                      <div className="btn-layout">
                        <Button
                          className="btn-full"
                          type="danger"
                          onClick={() => deleteAdmin(admin.id)}>
                          Delete
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
          </>
        ))}
        {admins.length > 0 && (
          <div className="pagination-container">
            <Pagination
              current={pagination.current_page}
              pageSize={10}
              total={pagination.total_data}
              onChange={(page) =>
                setPagination({ ...pagination, current_page: page })
              }
            />
          </div>
        )}
      </Content>
    </Layout>
  );
}

export default { AdminList };
