import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Col,
  Icon,
  Row,
  Layout,
  Table,
  Typography,
  message,
} from 'antd';

import moment from 'moment';

import ReportApi from 'api/report';
import UsersApi from 'api/v3/user';

const { Header, Content } = Layout;
const { Title } = Typography;

const UserCampaigns = () => {
  const { campaign_id } = useParams();

  const [campaigns, setCampaigns] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [columns, setColumns] = useState([]);

  const COLUMN_TEMPLATE_A = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Domisili',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Telp',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  const COLUMN_TEMPLATE_B = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  const manipulateDataWithTableProps = (data) => {
    data.forEach((datum, index) => {
      datum.number = index + 1;

      datum['Data Created'] = moment(datum.created_at).format('DD/MMMM/YYYY');
      datum['Time Created'] = moment(datum.created_at).format('hh:mm:ss');
    });

    setCampaigns(data);
  };

  const exportToCSV = async () => {
    if (campaign_id === '2') {
      const { data } = await ReportApi.footer();

      const link = document.createElement('a');
      link.href = data.data.url;
      link.download = data.data.file_name;
      link.click();

      message.success('Data berhasil diunduh, mohon tunggu');
    }

    if (campaign_id === '3') {
      const { data } = await ReportApi.promotion();

      const link = document.createElement('a');
      link.href = data.data.url;
      link.download = data.data.file_name;
      link.click();

      message.success('Data berhasil diunduh, mohon tunggu');
    }
  };

  useEffect(() => {
    const getUsersCampaign = async (campaign_id) => {
      try {
        setLoadingPage(true);

        const { data } = await UsersApi.getByCampaign(campaign_id);
        const users = data.data;

        manipulateDataWithTableProps(users);
      } catch {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      } finally {
        setLoadingPage(false);
      }
    };

    if (campaign_id === '2') {
      setColumns(COLUMN_TEMPLATE_B);
    } else {
      setColumns(COLUMN_TEMPLATE_A);
    }

    getUsersCampaign(campaign_id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  return (
    <Layout className="rata-cms-users-campaign pd-cms-form-1">
      <Header>
        <Title> Campaign</Title>
      </Header>

      <Content>
        <Row>
          <Col xs={24}>
            <Button
              type="primary"
              style={{ marginBottom: 20 }}
              onClick={exportToCSV}>
              <Icon type="download" />
              Download Report
            </Button>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Table
              dataSource={campaigns}
              columns={columns}
              loading={loadingPage}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default UserCampaigns;
