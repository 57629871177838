import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash';

import { Icon, Layout, Typography } from 'antd';

import { alertErr } from 'utils';
import HistoryService from 'api/history';

import HistoryItem from 'components/history/HistoryItem';
import { PageSpinner } from 'components/page-spinner';
import HistoryModal from 'components/history/HistoryModal';

const { Header, Content } = Layout;
const { Title } = Typography;

const ClinicHistories = () => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    currentPage: 0,
    totalPage: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [openModal, setIsOpenModal] = useState(false);
  const [clinicHistories, setClinicHistories] = useState([]);

  const [clinicDetailHistory, setClinicDetailHistory] = useState({});

  const handleLoadMore = () => setPage((curr) => curr + 1);

  const handleOpenDetailHistory = async (val) => {
    try {
      setIsLoadingDetail(true);
      setIsOpenModal(true);

      const response = await HistoryService.getHistoryById(val);

      const { data } = response.data;
      setClinicDetailHistory(data);
    } catch (error) {
      alertErr(error);
    } finally {
      setIsLoadingDetail(false);
    }
  };

  const handleCloseDetailHistory = () => {
    setIsOpenModal(false);
    setClinicDetailHistory({});
    setIsLoadingDetail(false);
  };

  const getHistories = async () => {
    try {
      const response = await HistoryService.getHistories(id, page);

      const { data, current_page, total_page } = response.data;

      setClinicHistories([...clinicHistories, ...data]);
      setPagination({
        ...pagination,
        currentPage: current_page,
        totalPage: total_page,
      });
    } catch (err) {
      alertErr(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    id && getHistories();
  }, [id, page]);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Layout>
      <Header>
        <Title>
          <Link to="/cms/clinics/manage">
            <Icon type="caret-left" />
          </Link>
          Clinic Histories
        </Title>
      </Header>

      <Content>
        <HistoryItem
          histories={clinicHistories}
          onLoadMore={handleLoadMore}
          pagination={pagination}
          onOpenDetailHistory={(val) => handleOpenDetailHistory(val)}
        />
        <HistoryModal
          open={openModal}
          isLoading={isLoadingDetail}
          onCloseDetailHistory={handleCloseDetailHistory}
          data={clinicDetailHistory}
        />
      </Content>
    </Layout>
  );
};

export default ClinicHistories;
