import React, { useState } from 'react';

import parser from 'html-react-parser';

import { Icon, message, Modal, Row, Typography } from 'antd';

import { capitalize } from 'utils';

import { PageSpinner } from 'components/page-spinner';

import './style.scss';

const HistoryModal = (props) => {
  const { data, open, onCloseDetailHistory, isLoading } = props;

  const [oldExpandable, setOldExpanable] = useState(false);
  const [oldCounter, setOldCounter] = useState(0);
  const [newExpandable, setNewExpanable] = useState(false);
  const [newCounter, setNewCounter] = useState(0);

  const copyToClipboard = (str) => {
    if (!str) return '';

    const cb = navigator.clipboard;
    const paragraph = str;

    cb.writeText(paragraph).then(() => {
      message.success('Text berhasil disalin ke clipboard');
    });
  };

  const handleExpandable = (val) => {
    if (val === 'old') {
      setOldExpanable(true);
      setOldCounter(!oldExpandable ? oldCounter + 0 : oldCounter + 1);
    }
    if (val === 'new') {
      setNewExpanable(true);
      setNewCounter(!newExpandable ? newCounter + 0 : newCounter + 1);
    }
  };

  const handleCollapse = (val) => {
    if (val === 'old') {
      setOldExpanable(false);
      setOldCounter(!oldExpandable ? oldCounter + 0 : oldCounter + 1);
    }
    if (val === 'new') {
      setNewExpanable(false);
      setNewCounter(!newExpandable ? newCounter + 0 : newCounter + 1);
    }
  };

  return (
    <Modal
      title={
        <Typography.Title
          style={{ marginBottom: 0 }}
          level={4}>{`Change the ${capitalize(
          data.menu_column
        )}`}</Typography.Title>
      }
      visible={open}
      footer={false}
      onCancel={onCloseDetailHistory}>
      {isLoading ? (
        <PageSpinner />
      ) : (
        <>
          <Row type="flex" justify="space-between" align="middle">
            <Typography.Text strong style={{ fontSize: 18 }}>
              Old Value
            </Typography.Text>
            {data.old_detail && data.history_type === 'TYPE_LINK' && (
              <Icon
                type="copy"
                style={{ marginLeft: 8 }}
                onClick={() => copyToClipboard(data.old_detail)}
              />
            )}
          </Row>

          {data.old_detail ? (
            <>
              {data.history_type === 'TYPE_IMAGE' && (
                <div style={{ width: '100%', height: 300 }}>
                  <img
                    src={data.old_detail}
                    alt="Old Value Image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
              {data.history_type === 'TYPE_COLOR' && (
                <div
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundColor: data.old_detail,
                  }}
                />
              )}
              {data.history_type === 'TYPE_LINK' && (
                <a
                  href={data.old_detail}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1155cc' }}>
                  {data.old_detail}
                </a>
              )}
              {data.history_type === 'TYPE_CONTENT' && (
                <>
                  <Typography.Title
                    key={oldCounter}
                    style={{ fontSize: 'inherit' }}
                    ellipsis={{
                      expandable: true,
                      rows: 10,
                      onExpand: () => handleExpandable('old'),
                    }}>
                    {parser(data.old_detail)}
                  </Typography.Title>
                  {oldExpandable && (
                    <Typography.Text
                      strong
                      onClick={() => handleCollapse('old')}
                      style={{ cursor: 'pointer' }}>
                      <span style={{ color: '#1890ff' }}>Collapse</span>
                    </Typography.Text>
                  )}
                </>
              )}
            </>
          ) : (
            '-'
          )}

          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ marginTop: 30 }}>
            <Typography.Text strong style={{ fontSize: 18 }}>
              New Value
            </Typography.Text>
            {data.new_detail && data.history_type === 'TYPE_LINK' && (
              <Icon
                type="copy"
                style={{ marginLeft: 8 }}
                onClick={() => copyToClipboard(data.new_detail)}
              />
            )}
          </Row>

          {data.new_detail ? (
            <>
              {data.history_type === 'TYPE_IMAGE' && (
                <div style={{ width: '100%', height: 300 }}>
                  <img
                    src={data.new_detail}
                    alt="New Value Image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              )}
              {data.history_type === 'TYPE_COLOR' && (
                <div
                  style={{
                    width: '100%',
                    height: 200,
                    backgroundColor: data.new_detail,
                  }}
                />
              )}
              {data.history_type === 'TYPE_LINK' && (
                <a
                  href={data.new_detail}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1155cc' }}>
                  {data.new_detail}
                </a>
              )}
              {data.history_type === 'TYPE_CONTENT' && (
                <>
                  <Typography.Title
                    key={newCounter}
                    style={{ fontSize: 'inherit' }}
                    ellipsis={{
                      expandable: true,
                      rows: 10,
                      onExpand: () => handleExpandable('new'),
                    }}>
                    {parser(data.new_detail)}
                  </Typography.Title>
                  {newExpandable && (
                    <Typography.Text
                      strong
                      onClick={() => handleCollapse('new')}
                      style={{ cursor: 'pointer' }}>
                      <span style={{ color: '#1890ff' }}>Collapse</span>
                    </Typography.Text>
                  )}
                </>
              )}
            </>
          ) : (
            '-'
          )}
        </>
      )}
    </Modal>
  );
};

export default HistoryModal;
