export const menus = [
  {
    type: 'Menu',
    name: 'Dashboard',
    link: '/cms/dashboard',
    menu_id: 'nav-menu-dashboard',
  },
  {
    type: 'SubMenu',
    name: 'Homepage',
    menu_id: 'nav-menu-homepage',
    children: [
      {
        type: 'Menu',
        name: 'Before After',
        link: '/cms/home/testimonials',
        menu_id: 'nav-menu-homepage--before-after',
      },
      {
        type: 'Menu',
        name: 'Rata Edu',
        link: '/cms/home/rata-edu',
        menu_id: 'nav-menu-homepage--rata-edu',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Comparisons',
    menu_id: 'nav-menu-comparisons',
    children: [
      {
        type: 'Menu',
        name: 'Section',
        link: '/cms/comparisons/section',
        menu_id: 'nav-menu-comparisons--section',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/comparisons/manage',
        menu_id: 'nav-menu-comparisons--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Benefits',
    menu_id: 'nav-menu-benefits',
    children: [
      {
        type: 'Menu',
        name: 'Section',
        link: '/cms/benefits/section',
        menu_id: 'nav-menu-benefits--section',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/benefits/manage',
        menu_id: 'nav-menu-benefits--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Kols',
    menu_id: 'nav-menu-kols',
    children: [
      {
        type: 'Menu',
        name: 'Section',
        link: '/cms/kols/section',
        menu_id: 'nav-menu-kols--section',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/kols/manage',
        menu_id: 'nav-menu-kols--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Testimonies',
    menu_id: 'nav-menu-testimonies',
    children: [
      {
        type: 'Menu',
        name: 'Section',
        link: '/cms/testimonies/section',
        menu_id: 'nav-menu-testimonies--section',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/testimonies/manage',
        menu_id: 'nav-menu-testimonies--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Apa Itu Rata',
    menu_id: 'nav-menu-apa-itu-rata',
    children: [
      {
        type: 'Menu',
        name: 'Banner',
        link: '/cms/apa-itu-rata/banner',
        menu_id: 'nav-menu-apa-itu-rata--banner',
      },
      {
        type: 'Menu',
        name: 'FAQ',
        link: '/cms/apa-itu-rata/faq',
        menu_id: 'nav-menu-apa-itu-rata--faq',
      },
      {
        type: 'Menu',
        name: 'Video',
        link: '/cms/apa-itu-rata/video',
        menu_id: 'nav-menu-apa-itu-rata--video',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Cara Konsultasi',
    menu_id: 'nav-menu-cara-konsultasi',
    children: [
      {
        type: 'Menu',
        name: 'Banner',
        link: '/cms/cara-konsultasi/banner',
        menu_id: 'nav-menu-cara-konsultasi--banner',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/cara-konsultasi/manage',
        menu_id: 'nav-menu-cara-konsultasi--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Campaigns',
    menu_id: 'nav-menu-campaign',
    children: [
      {
        type: 'Menu',
        name: 'Campaign Ads',
        link: '/cms/campaign-ads',
        menu_id: 'nav-menu-campaign--ads',
      },
      {
        type: 'Menu',
        name: 'Newsletter Footer',
        link: '/cms/users/campaigns/2',
        menu_id: 'nav-menu-campaign--newsletter-footer',
      },
      {
        type: 'Menu',
        name: 'Newsletter Promotion',
        link: '/cms/users/campaigns/3',
        menu_id: 'nav-menu-campaign--newsletter-promotion',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Clinic Rata',
    menu_id: 'nav-menu-clinic-rata',
    children: [
      {
        type: 'Menu',
        name: 'SEO Desc',
        link: '/cms/clinics/seo',
        menu_id: 'nav-menu-clinic-rata--seo-desc',
      },
      {
        type: 'Menu',
        name: 'Manage',
        link: '/cms/clinics/manage',
        menu_id: 'nav-menu-clinic-rata--manage',
      },
    ],
  },
  {
    type: 'SubMenu',
    name: 'Quiz',
    menu_id: 'nav-menu-quiz',
    children: [
      {
        type: 'Menu',
        name: 'Landing',
        link: '/cms/quiz/edit',
        menu_id: 'nav-menu-quiz--landing',
      },
      {
        type: 'Menu',
        name: 'Result',
        link: '/cms/quiz/result-a',
        menu_id: 'nav-menu-quiz--result',
      },
      {
        type: 'Menu',
        name: 'Old Quiz Report',
        link: '/cms/quiz/reports',
        menu_id: 'nav-menu-quiz--old-quiz-report',
      },
      {
        type: 'Menu',
        name: 'New Quiz Report',
        link: '/cms/quiz/reports/new',
        menu_id: 'nav-menu-quiz--new-quiz-report',
      },
    ],
  },
  {
    type: 'Menu',
    name: 'Article',
    link: '/cms/articles',
    menu_id: 'nav-menu-article',
  },
  {
    type: 'SubMenu',
    name: 'Comment',
    menu_id: 'nav-menu-comment',
    children: [
      {
        type: 'Menu',
        name: `Waiting Approval`,
        link: '/cms/comments/waiting_approval',
        menu_id: 'nav-menu-comment--waiting-approval',
      },
      {
        type: 'Menu',
        name: 'Approved Comment',
        link: '/cms/comments/approved',
        menu_id: 'nav-menu-comment--approved-comment',
      },
      {
        type: 'Menu',
        name: 'Rejected Comment',
        link: '/cms/comments/rejected',
        menu_id: 'nav-menu-comment--rejected-comment',
      },
      {
        type: 'Menu',
        name: 'Marketing Reply',
        link: '/cms/comments/admin_rata',
        menu_id: 'nav-menu-comment--marketing-reply',
      },
    ],
  },
  {
    type: 'Menu',
    name: 'Forms',
    link: '/cms/forms',
    menu_id: 'nav-menu-form',
  },
  {
    type: 'Menu',
    name: 'Newsletter',
    link: '/cms/newsletter-section',
    menu_id: 'nav-menu-newsletter',
  },
  {
    type: 'Menu',
    name: 'Promotions',
    link: '/cms/promotions',
    menu_id: 'nav-menu-promotions',
  },
  {
    type: 'Menu',
    name: 'Our Offers',
    link: '/cms/our-offers',
    menu_id: 'nav-menu-our-offers',
  },
  {
    type: 'Menu',
    name: 'Floating Promo Banner',
    link: '/cms/floating-promo-banners',
    menu_id: 'nav-menu-floating-promo-banner﻿',
  },
  {
    type: 'Menu',
    name: 'General Content',
    link: '/cms/general-content',
    menu_id: 'nav-menu-general-content',
  },
  {
    type: 'SubMenu',
    name: 'Terms and Conditions',
    menu_id: 'nav-menu-tnc',
    children: [
      {
        type: 'Menu',
        name: 'Ketentuan Penggunaan',
        link: '/cms/tnc/ketentuan-penggunaan',
        menu_id: 'nav-menu-tnc--terms',
      },
      {
        type: 'Menu',
        name: 'Kebijakan Privasi',
        link: '/cms/tnc/kebijakan-privasi',
        menu_id: 'nav-menu-tnc--privacy',
      },
    ],
  },
  {
    type: 'Menu',
    name: 'Tracking Events',
    link: '/cms/tracking/events',
    menu_id: 'nav-menu-tracking-events',
  },
  {
    type: 'Menu',
    name: 'Protokol Covid-19',
    link: '/cms/protokol-covid',
    menu_id: 'nav-menu-covid',
  },
  {
    type: 'Menu',
    name: 'Untuk Dokter',
    link: '/cms/untuk-dokter',
    menu_id: 'nav-menu-untuk-dokter',
  },
  {
    type: 'Menu',
    name: 'Generator',
    link: '/cms/generator',
    menu_id: 'nav-menu-generator',
  },
  {
    type: 'Menu',
    name: 'Administrator',
    menu_id: 'nav-menu-adminstrator',
    link: '/cms/admin',
  },
];
