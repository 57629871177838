import api from './index';

export default {
  getDescription() {
    return api.get(`/desc_pages`);
  },
  updateDescription(id, payload) {
    return api.put(`/desc_pages/${id}`, { request: payload });
  },
};
