import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Layout, Row, Typography, Button, message, Modal } from 'antd';
import _ from 'lodash';
import { PageSpinner } from 'components/page-spinner';
import './style.scss';
import { PdSortable } from 'components/pd-sortable';
import buyingStepAPI from 'api/v2/buyingStep';
import { alertErr } from 'utils';

const { Header, Content } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

export const CaraKonsultasi = () => {
  const [buyingSteps, setBuyingSteps] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);

  async function deleteBuyingStep(id) {
    try {
      await buyingStepAPI.deleteBuyingStep(id);
      getBuyingSteps();
      message.success('Item has been deleted');
    } catch (err) {
      alertErr(err);
    }
  }

  function handleDelete(id) {
    confirm({
      title: 'Are you sure to delete this?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBuyingStep(id);
      },
      onCancel() {},
    });
  }

  async function getBuyingSteps() {
    try {
      setLoadingPage(true);
      const res = await buyingStepAPI.listBuyingSteps();
      const buyingSteps = _.get(res, 'data.data');
      setBuyingSteps(buyingSteps);
    } catch (err) {
      alertErr(err);
    } finally {
      setLoadingPage(false);
    }
  }

  useEffect(() => {
    getBuyingSteps();
  }, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  const SortableItem = (buyingStep) => {
    return (
      <Row
        type="flex"
        align="middle"
        justify="space-between"
        gutter={12}
        className="step-item draggable-item">
        <Col span={5}>
          <div className="profile-image">
            <img src={buyingStep.media_url} alt={buyingStep.title} />
          </div>
        </Col>
        <Col span={7}>
          <div className="container--center">
            <Title level={4}>{buyingStep.title}</Title>
          </div>
        </Col>

        {/* button group */}
        <Col span={12}>
          <Row type="flex" justify="end">
            <Button
              type="danger"
              onClick={handleDelete.bind(this, buyingStep.id)}>
              Delete
            </Button>
            <Button style={{ marginLeft: 24 }} type="primary">
              <Link to={`/cms/cara-konsultasi/manage/edit?id=${buyingStep.id}`}>
                Edit
              </Link>
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  async function onSortEnd({ oldIndex, newIndex }) {
    let list = [...buyingSteps];
    let temp = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(newIndex, 0, temp);

    const payload = list.map((item, index) => {
      return {
        id: item.id,
        order_level: index,
      };
    });

    try {
      setBuyingSteps(list);
      await buyingStepAPI.updateBuyingStepOrder(payload);
      message.success('Ordering has been updated');
    } catch (err) {
      alertErr(err);
    }
  }

  return (
    <Layout className="pd-cms-buying-step pd-cms-form-1">
      <Header>
        <Title>Cara Konsultasi</Title>
      </Header>

      <Content id="drag-content">
        <Button
          type="primary"
          style={{ fontSize: '18px' }}
          className="pd-margin-bottom-lg">
          <Link to={'/cms/cara-konsultasi/manage/create'}>
            Create New Cara Konsultasi
          </Link>
        </Button>
        <PdSortable
          render={SortableItem}
          items={buyingSteps}
          onSortEnd={onSortEnd}
          lockAxis="y"
          helperClass="item-dragged"
          pressDelay={100}
          helperContainer={() => document.getElementById('drag-content')}
        />
      </Content>
    </Layout>
  );
};
