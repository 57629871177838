import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import FormServices from 'api/forms';

import {
  Button,
  Input,
  Form,
  Layout,
  Typography,
  Row,
  message,
  Switch,
  Icon,
} from 'antd';

import { FIELDS } from '../constant';
import { phoneRegExp, slugRegExp } from 'utils';

import '../style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const CreateEditForm = ({ form }) => {
  const { id } = useParams();
  const history = useHistory();

  const [loadingButton, setLoadingButton] = useState(false);
  const [content, setContent] = useState({});

  const { getFieldDecorator } = form;

  const getSingleForm = async () => {
    const response = await FormServices.getFormById(id);

    const { data } = response.data;

    const res = {
      ...data,
      destination_number: data.destination_number.substring(2),
    };

    setContent(res);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        const payload = {
          ...values,
          destination_number: `62${values.destination_number}`,
          format: values.format.replaceAll('\n', '%0a'),
          author: { id: localStorage.getItem('current_admin_id') },
        };

        try {
          setLoadingButton(true);

          if (id) await FormServices.updateForm(id, payload);
          else await FormServices.createForm(payload);

          message.success(`Berhasil ${id ? 'mengubah' : 'membuat'} form`);

          history.push('/cms/forms');
        } catch (err) {
          if (err.response.data.message) {
            const errMessage = err.response.data.message;
            message.error(errMessage);
          } else {
            message.error('Tidak dapat menghubungi server, cek koneksi');
          }
        } finally {
          setLoadingButton(false);
        }
      }
    });
  };

  useEffect(() => {
    id && getSingleForm();

    return () => setContent({});
  }, [id]);

  return (
    <Layout className="rata-cms-promo-floating-banners pd-cms-form-1">
      <Header>
        <Title>
          <Icon type="caret-left" onClick={() => history.goBack()} />
          {id ? 'Edit' : 'Create'} Form
        </Title>
      </Header>

      <Content>
        <Row>
          <Form
            onSubmit={(event) => handleSubmit(event)}
            className="pd-admin-create-form">
            <Form.Item label="Purpose">
              {getFieldDecorator(FIELDS.PURPOSE, {
                rules: [
                  { required: true, message: 'Please input the purpose!' },
                ],
                initialValue: content[FIELDS.PURPOSE] || '',
              })(<Input placeholder="Input the purpose form" />)}
            </Form.Item>

            <Form.Item label="Slug">
              {getFieldDecorator(FIELDS.SLUG, {
                rules: [
                  { required: true, message: 'Please input the slug!' },
                  {
                    pattern: slugRegExp,
                    message: 'Must be a valid slug. ex: example-test',
                  },
                ],
                initialValue: content[FIELDS.SLUG] || '',
              })(<Input placeholder="Input the slug form" />)}
            </Form.Item>

            <Form.Item label="Destination Number">
              {getFieldDecorator(FIELDS.DESTINATION_NUMBER, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the destination number!',
                  },
                  {
                    pattern: phoneRegExp,
                    message: 'Must be a valid phone number. (13digits)',
                  },
                ],
                initialValue: content.destination_number || '',
              })(
                <Input
                  addonBefore="+62"
                  placeholder="Input the destination number! (89573119321)"
                />
              )}
            </Form.Item>

            <Form.Item label="Button Text">
              {getFieldDecorator(FIELDS.BUTTON_TEXT, {
                rules: [
                  { required: true, message: 'Please input the button text!' },
                ],
                initialValue: content[FIELDS.BUTTON_TEXT] || '',
              })(<Input placeholder="Input the button text" />)}
            </Form.Item>

            <Form.Item label="Active">
              {getFieldDecorator(FIELDS.ACTIVE, {
                valuePropName: 'checked',
                initialValue: content[FIELDS.ACTIVE] || false,
              })(<Switch checkedChildren="Yes" unCheckedChildren="No" />)}
            </Form.Item>

            <Form.Item label="Format">
              {getFieldDecorator(FIELDS.FORMAT, {
                rules: [
                  {
                    required: true,
                    message: 'Please input the format!',
                  },
                ],
                initialValue: id
                  ? String(content[FIELDS.FORMAT]).replaceAll('%0a', '\n')
                  : '',
              })(<Input.TextArea rows={4} placeholder="Input format" />)}
            </Form.Item>

            <Form.Item>
              <Button
                className="btn-full"
                type="primary"
                htmlType="submit"
                loading={loadingButton}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Content>
    </Layout>
  );
};

export const CreateEditFormAppointment = Form.create({
  name: 'validate_create_edit_form_appointment',
})(CreateEditForm);

export default CreateEditForm;
