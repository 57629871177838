import api from './index';

export default {
  get() {
    return api.get(`/section_images`);
  },
  create(payload) {
    return api.post(`/section_images`, { request: payload });
  },
  update(id, payload) {
    return api.put(`/section_images/${id}`, { request: payload });
  },
  getImagesPromotion(id) {
    return api.get(`/section_images/promotions/${id}`);
  },
  delete(id) {
    return api.delete(`/section_images/${id}`);
  },
};
