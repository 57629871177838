import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Layout, message, Row, Typography } from 'antd';

import AuthenticationApi from 'api/authentication';

import './style.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  const [admin, setAdmin] = useState({});

  const accessToken = localStorage.getItem('cms_token');

  const getMeLogin = async () => {
    try {
      const { data: me } = await AuthenticationApi.getMe(accessToken);

      setAdmin(me.data);
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    }
  };

  useEffect(() => {
    getMeLogin();
  }, []);

  return (
    <Layout>
      <Header>
        <Title id="title-dashboard-page">Dashboard</Title>
      </Header>

      <Content>
        <Row gutter={[20, 20]}>
          <Col span={4} id="name-key">
            Name
          </Col>
          <Col
            span={20}
            id="name-value"
            style={{ textTransform: 'capitalize' }}>
            {admin.name}
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={4} id="email-key">
            Email
          </Col>
          <Col span={20} id="email-value">
            {admin.email}
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col span={4} id="status-key">
            Status
          </Col>
          <Col
            span={20}
            id="status-value"
            style={{ textTransform: 'capitalize' }}>
            {admin.status}
          </Col>
        </Row>

        <div style={{ marginTop: 40 }}>
          <Link
            id="btn-admin-edit"
            className="btn-link edit"
            to={{
              pathname: `/cms/admin/edit?id=${admin.id}`,
              data: { admin },
            }}
            style={{ marginRight: 20 }}>
            Edit
          </Link>
          <Link
            id="btn-admin-change-password"
            to="/cms/admin/edit/change-password"
            className="btn-link change">
            Change Password
          </Link>
        </div>
      </Content>
    </Layout>
  );
};

export default Dashboard;
