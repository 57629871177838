import api from './index';

export default {
  updatePageContent(payload) {
    return api.put(`/benefit_pages`, { request: payload });
  },
  getPageContent() {
    return api.get(`/benefit_pages`);
  },
  listBenefits(q, page, size) {
    let url = '/benefits';
    const filters = [];

    q.trim() !== '' && filters.push(`q=${q}`);
    page && filters.push(`page=${page}`);
    size && filters.push(`size=${size}`);

    url = `${url}?${filters.join('&')}`;

    return api.get(url);
  },
  detailBenefit(id) {
    return api.get(`/benefits/${id}`);
  },
  createBenefit(payload) {
    return api.post(`/benefits`, { request: payload });
  },
  updateBenefitDetail(id, payload) {
    return api.put(`/benefits/${id}`, { request: payload });
  },
  updateBenefitOrder(payload) {
    return api.put(`/benefits`, { request: payload });
  },
  deleteBenefit(id) {
    return api.delete(`/benefits/${id}`);
  },
};
