import React, { useEffect, useState } from 'react';
import { Button, Form, Icon, Input, Layout, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import BenefitsApi from 'api/benefit';

import { PageSpinner } from 'components/page-spinner';
import { PdUploader } from 'components/pd-uploader';

import '../style.scss';
import 'react-quill/dist/quill.snow.css';

const { Header, Content } = Layout;
const { Title } = Typography;
const FIELDS = {
  TITLE: 'title',
  MEDIA_URL: 'media_url',
  MEDIA_URL_ALT: 'media_url_alt',
};

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'link',
];

export function BenefitsCreateEdit({ form, match, history }) {
  const [benefit, setBenefit] = useState({});
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    async function getBenefit() {
      setLoadingPage(true);
      try {
        const { data } = await BenefitsApi.detailBenefit(id);
        const benefitData = data.data;

        setBenefit(benefitData);
      } finally {
        setLoadingPage(false);
      }
    }

    if (id) {
      getBenefit();
    }
  }, [id]);

  const { getFieldDecorator } = form;

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!benefit.media_url) {
        message.error('Benefit Picture must be filled');
        return;
      }

      if (!err) {
        try {
          setLoadingButton(true);

          const payload = {
            ...benefit,
            ...values,
          };

          if (id) {
            await BenefitsApi.updateBenefitDetail(id, payload);
            message.success('Data has been updated');
          } else {
            await BenefitsApi.createBenefit(payload);
            message.success('Data has been created');
          }

          history.push({
            pathname: '/cms/benefits/manage',
          });
        } catch (err) {
          const errMessage = _.get(err, 'response.data.message');
          message.error(errMessage || 'Cannot connect to the server');
        } finally {
          setLoadingButton(false);
        }
      }
    });
  }

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="pd-cms-form-1">
      <Header>
        <Title>
          <Link to="/cms/benefits/manage">
            <Icon type="caret-left" />
          </Link>
          {id ? 'Edit' : 'Add New'} Benefit
        </Title>
      </Header>
      <Content>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Form.Item label="Title">
            {getFieldDecorator(FIELDS.TITLE, {
              rules: [{ required: true, message: 'Please input title!' }],
              initialValue: benefit[FIELDS.TITLE] || '',
            })(
              <ReactQuill
                placeholder="Write your title here"
                modules={modules}
                formats={formats}
              />
            )}
          </Form.Item>

          <Form.Item label="Image - 1:1 (60x60)" required>
            <PdUploader
              purpose="benefit"
              imgUrl={_.get(benefit, FIELDS.MEDIA_URL, '')}
              afterUpload={(url) => {
                setBenefit((prevState) => ({
                  ...prevState,
                  [FIELDS.MEDIA_URL]: url,
                }));
              }}
            />
          </Form.Item>

          <Form.Item label="Image Alt Text">
            {getFieldDecorator(FIELDS.MEDIA_URL_ALT, {
              rules: [
                {
                  pattern: /^(?!\s)[\s\S]*(?<!\s)$/,
                  message:
                    'Image ALT should not start or end characters with spaces',
                },
              ],
              initialValue: benefit[FIELDS.MEDIA_URL_ALT],
            })(<Input placeholder="Please enter image alt text" />)}
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-full"
              type="primary"
              htmlType="submit"
              loading={loadingButton}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
}

export const BenefitsCreateEditForm = Form.create({
  name: 'validate_benefits_edit',
})(BenefitsCreateEdit);

export default { BenefitsCreateEdit };
